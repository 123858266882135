import { Review } from "common/types/review";

export const fbReviews: Review[] = [
  {
    type: "fb",
    review_id: "",
    customer_name: "Peter Maravilla",
    image_link:
      "https://scontent.fmnl3-1.fna.fbcdn.net/v/t39.30808-1/472331419_1190800712499683_4774587276124163195_n.jpg?stp=cp6_dst-jpg_s40x40_tt6&_nc_cat=107&ccb=1-7&_nc_sid=e99d92&_nc_ohc=DTASDyoOJs8Q7kNvgGaziWC&_nc_zt=24&_nc_ht=scontent.fmnl3-1.fna&_nc_gid=Af5-m16Lmle4rrt8AYtG9mH&oh=00_AYAShTsRS-k5jrshgl2z357Ht8eAmoN5kgvmiF43Sa7DNw&oe=678CD9E9",
    review_text:
      "Highly recommended quality and very cheap price and kind staff 10/10",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "January 13, 2025",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Virgil Leyeza",
    image_link:
      "https://scontent.fmnl3-1.fna.fbcdn.net/v/t39.30808-1/473145130_3045793202244612_8027202488088905824_n.jpg?stp=c40.0.1034.1034a_cp0_dst-jpg_s40x40_tt6&_nc_cat=107&ccb=1-7&_nc_sid=e99d92&_nc_ohc=ECQByQtj18kQ7kNvgHIdhPP&_nc_zt=24&_nc_ht=scontent.fmnl3-1.fna&_nc_gid=Af5-m16Lmle4rrt8AYtG9mH&oh=00_AYBnwtfTVIe6AsYWKrq6JeJVjyuN7A2teOvRy0kzcXyKcA&oe=678CE647",
    review_text:
      "sobrang sulit tlga sa PC WORTH, very accommodating ren yung staff nila very thankful ren ako nabuo ko ung dream pc ko 🤘😊",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "December 17, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Baeron Vallar",
    image_link:
      "https://scontent.fmnl37-2.fna.fbcdn.net/v/t39.30808-1/416149377_1089151172278738_8454822832206737408_n.jpg?stp=cp0_dst-jpg_s40x40_tt6&_nc_cat=103&ccb=1-7&_nc_sid=e99d92&_nc_ohc=Rc-a8akq8pMQ7kNvgGoar8I&_nc_zt=24&_nc_ht=scontent.fmnl37-2.fna&_nc_gid=AyP7MNgvTBRbjQP1iXd2aSJ&oh=00_AYAGRUhYMvJvRLKaqi5fep5ULhzokpdYHUDSRsOqZ1fb4w&oe=678CF818",
    review_text: "Sobrang responsive nila kahit off hours na 💯💯",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "December 15, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "RD Leong",
    image_link:
      "https://scontent.fmnl3-1.fna.fbcdn.net/v/t39.30808-1/472584412_1310287533727326_8083846013087994448_n.jpg?stp=cp0_dst-jpg_s40x40_tt6&_nc_cat=110&ccb=1-7&_nc_sid=e99d92&_nc_ohc=vlLa74mJPrAQ7kNvgF3wkyi&_nc_zt=24&_nc_ht=scontent.fmnl3-1.fna&_nc_gid=A0cmXIdGtpuYWrbgjqVGYFQ&oh=00_AYBztZISz40rZjttVufeJEm6ePtde1tnWLpxlE3aDVvXgQ&oe=678D0E0F",
    review_text:
      "Recommended! okay na okay yung costumer service nila at ang mura ng mga pc parts nila kumpara sa mga ibang store!",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "December 10, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Charise Arnaez ",
    image_link:
      "https://scontent.fmnl3-2.fna.fbcdn.net/v/t39.30808-1/453619005_2477430965790016_300190011762559263_n.jpg?stp=cp6_dst-jpg_s40x40_tt6&_nc_cat=100&ccb=1-7&_nc_sid=e99d92&_nc_ohc=wDNwgSIlq0sQ7kNvgHabsnR&_nc_zt=24&_nc_ht=scontent.fmnl3-2.fna&_nc_gid=AyP7MNgvTBRbjQP1iXd2aSJ&oh=00_AYAXWFqyJ9yUvu0eRmBsbDNQofL0VjftP4Xdu8dgYYU0Qw&oe=678CD4FE",
    review_text:
      "OG ✨ highly recommended! babalik ulit para sa bagong setup soon 🥳",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "December 8, 2024",
  },

  {
    type: "fb",
    review_id: "",
    customer_name: "John Alrey",
    image_link:
      "https://scontent.fmnl3-1.fna.fbcdn.net/v/t39.30808-1/448306275_2264641967209494_4302743779541197014_n.jpg?stp=cp0_dst-jpg_s40x40_tt6&_nc_cat=110&ccb=1-7&_nc_sid=e99d92&_nc_ohc=HFjP91RRqpkQ7kNvgESbsKP&_nc_zt=24&_nc_ht=scontent.fmnl3-1.fna&_nc_gid=AU51ETV3rzUVHMrsL38B56g&oh=00_AYB7nueTzLCIRhlpQmSfo53gG6PpnBQGHyxzMDV0lSISqw&oe=678CF1C8",
    review_text:
      "1000/10 super worth it nung nabili kong GPU super accommodating pa ng page. Highly recommended",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "December 1, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Jay Cee",
    image_link:
      "https://scontent.fmnl37-1.fna.fbcdn.net/v/t39.30808-1/385436600_1016130159591086_6863492442074712310_n.jpg?stp=c0.398.1080.1080a_cp0_dst-jpg_s40x40_tt6&_nc_cat=106&ccb=1-7&_nc_sid=1d2534&_nc_ohc=OfbPLRgD2LEQ7kNvgGC1Db2&_nc_zt=24&_nc_ht=scontent.fmnl37-1.fna&_nc_gid=Ay1v2x_4PraMhP8rZxWkzat&oh=00_AYD_iPPitgqIXVoioFImR6r_UtVDyw3AOqOlJa3CklZyXg&oe=678CCF65",
    review_text: `Apakapogi ng build na ibinigay sakin sa presyong abot na abot mo pare!!! grabe din pasensya nila kaya wag kayong mahiya magpaassist at magtanong lalo kung baguhan ka sa pc builds...
THANK YOU ULIT ATE JHAN NG PC WORTH 😁`,
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: " December 1, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Michelle Cañadilla",
    image_link:
      "https://scontent.fmnl37-2.fna.fbcdn.net/v/t39.30808-1/473420169_9490845050950183_8514230478154648206_n.jpg?stp=cp0_dst-jpg_s40x40_tt6&_nc_cat=103&ccb=1-7&_nc_sid=1d2534&_nc_ohc=Vj3vvAZVOjYQ7kNvgHDS914&_nc_zt=24&_nc_ht=scontent.fmnl37-2.fna&_nc_gid=AK5hVUTru6D5QdMJ4T6_hNA&oh=00_AYCOdArG8rAY7mWd_Nq4dvN6Mj52l_RpBap_pkHWKLT89Q&oe=678CF412",
    review_text: `Highly recommended.
I bought a curved gaming monitor, yung MSI nila same day delivery. Thank you sa nag-assist sa akin. Responsive din sila talaga. More sales!`,
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "November 29, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Lk Cruz Laquindanum",
    image_link:
      "https://scontent.fmnl3-4.fna.fbcdn.net/v/t39.30808-1/450963463_8180695241981627_2595314961991942977_n.jpg?stp=cp0_dst-jpg_s40x40_tt6&_nc_cat=104&ccb=1-7&_nc_sid=e99d92&_nc_ohc=zEkUUC61XiwQ7kNvgHbZjZz&_nc_zt=24&_nc_ht=scontent.fmnl3-4.fna&_nc_gid=AIudpDDV6IAJKZ3GECaNML5&oh=00_AYDSbwfbrkpDc48Qz8i_bsDRc0qYqsUdLO9-vgU70SqvLA&oe=678CF66B",
    review_text:
      "mababait po staff nila at mas mura compared sa ibang shops. sa uulitin po. thanks!",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "November 26, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Nicole Hansen Gerald Yambao",
    image_link:
      "https://scontent.fmnl37-2.fna.fbcdn.net/v/t1.6435-1/49089568_2462951620385839_7001038954405298176_n.jpg?stp=c285.74.999.999a_cp0_dst-jpg_s40x40_tt6&_nc_cat=108&ccb=1-7&_nc_sid=e99d92&_nc_ohc=tZ4mnpGTW3kQ7kNvgFsEM21&_nc_zt=24&_nc_ht=scontent.fmnl37-2.fna&_nc_gid=AIudpDDV6IAJKZ3GECaNML5&oh=00_AYDJ0Vn2VQQtihbqU6qXm7F139M7dgs4mr3-H7wsS5sjTQ&oe=67AE92D5",
    review_text:
      "I absolutely adore what the PC WORTH staff has done to make our dream build come true! Prices are decent and comparable to stores in Gilmore along with top notch customer service. Definitely recommend!!",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "November 26, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Glennore Alejo",
    image_link:
      "https://scontent.fmnl37-2.fna.fbcdn.net/v/t39.30808-1/471782728_27941158362199047_5476188722771988602_n.jpg?stp=cp0_dst-jpg_s40x40_tt6&_nc_cat=108&ccb=1-7&_nc_sid=e99d92&_nc_ohc=BwHmmVO3XFoQ7kNvgGsgyYc&_nc_zt=24&_nc_ht=scontent.fmnl37-2.fna&_nc_gid=AIudpDDV6IAJKZ3GECaNML5&oh=00_AYBpRQnZY6i-QX8-fjCH8dNcPVURHbIsCiUcMlF5MYCelg&oe=678CE5B2",
    review_text:
      "Thank you PC Worth! The best in transaction, speedy delivery and affordable products.",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "November 24, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Juu David",
    image_link:
      "https://scontent.fmnl37-1.fna.fbcdn.net/v/t39.30808-1/463392090_10234098983277100_7034043178211847741_n.jpg?stp=cp0_dst-jpg_p40x40_tt6&_nc_cat=106&ccb=1-7&_nc_sid=e99d92&_nc_ohc=QobgqYaWAwwQ7kNvgF7Wo3G&_nc_zt=24&_nc_ht=scontent.fmnl37-1.fna&_nc_gid=A710HMTmxlKVhlOMw5W3flm&oh=00_AYBMC-73w_Q3xqhpiqshcKmzn8DSvOZ2oGauOu1Unt-Zeg&oe=678D0AAC",
    review_text:
      "Bought my pc here with 0 hassle, feeling ko sila pa nahassle kakatanong ko 😂 Decided to buy here kasi literally sila na ung cheapest options ko, not to mention, sobrang friendly ng staff. 10/10",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "November 15, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Huge Jin",
    image_link: "Huge Jin",
    review_text:
      "Fast delivery and well packed. ordered and delivered same day. cheapest price but quality product.",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "November 14, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Maan Tajaros",
    image_link:
      "https://scontent.fmnl37-2.fna.fbcdn.net/v/t39.30808-1/465886747_9393557054006881_4655105006013407059_n.jpg?stp=c0.380.720.720a_cp0_dst-jpg_s40x40_tt6&_nc_cat=103&ccb=1-7&_nc_sid=e99d92&_nc_ohc=nX4TILOZSYIQ7kNvgFx8_Pi&_nc_zt=24&_nc_ht=scontent.fmnl37-2.fna&_nc_gid=AJhVngfnXa2Nhx1dNT0j1-R&oh=00_AYCXXufw9YuHggTjLYot5jJmT2XjNZ3q58aL5Bd9Mcz7QA&oe=678CD3EC",
    review_text:
      "Got my partner's PC here (Earnshaw branch) last Oct 30 and the staff was sooooo good! We inquired around 3PM and they already informed us na 5PM ang cut off for PC building pero around 7pm~ na kami nakarating. We told them na rin na we can just go there the next day but the staffs were kind enough to do it (the PC building) there and then anyway! 😭 They even ",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "November 8, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Leonard Torres",
    image_link:
      "https://scontent.fmnl3-1.fna.fbcdn.net/v/t39.30808-1/429649389_7984482624902401_7578262486863912817_n.jpg?stp=c7.0.641.641a_cp0_dst-jpg_s40x40_tt6&_nc_cat=110&ccb=1-7&_nc_sid=e99d92&_nc_ohc=Ap0gOLshOJcQ7kNvgGyKYQW&_nc_zt=24&_nc_ht=scontent.fmnl3-1.fna&_nc_gid=AJhVngfnXa2Nhx1dNT0j1-R&oh=00_AYACqZ4BgoUVqDfNjA4GRlOdiiKnnzuem9JE77Q0HBnfpQ&oe=678CEB78",
    review_text:
      "Super sulit! You get what you pay for and very accomodating! Thanks again PC Worth 🙏🙏🙏",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "November 2, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Marc Doneill Balatayo",
    image_link:
      "https://scontent.fmnl3-2.fna.fbcdn.net/v/t39.30808-1/455827157_1826764127731690_5495831714237196444_n.jpg?stp=cp0_dst-jpg_s40x40_tt6&_nc_cat=105&ccb=1-7&_nc_sid=1d2534&_nc_ohc=OxWNO9K2KtwQ7kNvgH9JGcp&_nc_zt=24&_nc_ht=scontent.fmnl3-2.fna&_nc_gid=A0Z1_wIK2BMdPPSm3IoROiE&oh=00_AYAyZNLLndPQRWZbsGFOmDOR0LunnqPKieVs7dHNr_duCg&oe=678CCA79",
    review_text:
      "I highly recommend this store specially the main branch, very accommodating staffs. I really like their website which also had a feature like pc builder where you can DIY your own build according to your liking, so you got to check it out!",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "November 1, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Brent Allen Cueto Benedicto ",
    image_link:
      "https://scontent.fmnl3-3.fna.fbcdn.net/v/t1.6435-1/109233451_4792239660801700_7243022072833904387_n.jpg?stp=c0.6.544.543a_cp0_dst-jpg_s40x40_tt6&_nc_cat=111&ccb=1-7&_nc_sid=e99d92&_nc_ohc=qZFbSmN340QQ7kNvgG_j24e&_nc_zt=24&_nc_ht=scontent.fmnl3-3.fna&_nc_gid=A0Z1_wIK2BMdPPSm3IoROiE&oh=00_AYCVX5_RmGAG7lqIE-ggz5Yf1LzzCwezGXAEpnBOfI3cbA&oe=67AE87FA",
    review_text:
      "Very accommodated pag nag inquire and after processing ng item they will ask detail regarding on it which I appreciate, Thanks and Goods yung GPU no issue and sealed. 🫡",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "October 30, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Eru Shi",
    image_link:
      "https://scontent.fmnl37-1.fna.fbcdn.net/v/t39.30808-1/471189884_2930076537161915_2817630129300636881_n.jpg?stp=cp0_dst-jpg_s40x40_tt6&_nc_cat=109&ccb=1-7&_nc_sid=e99d92&_nc_ohc=pHd6BA62XJkQ7kNvgEqToQZ&_nc_zt=24&_nc_ht=scontent.fmnl37-1.fna&_nc_gid=AgaE0rcE6VuaTGvV-5evKfs&oh=00_AYCGZ98IQh_OmeWPZvlay-bUl5k9J78OsNwuNvXUgSDStA&oe=678CEDFB",
    review_text:
      "I bought a Ryzen 5 5600 CPU and an MSI B550M Pro VDH Wifi on their Earnshaw Branchc, Good and polite response on their chats, gives good instructions with very nice customer service",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "October 24, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Cyrus Manalo ",
    image_link:
      "https://scontent.fmnl37-2.fna.fbcdn.net/v/t39.30808-1/316420141_5923304414398531_3257835870588125041_n.jpg?stp=cp0_dst-jpg_s40x40_tt6&_nc_cat=108&ccb=1-7&_nc_sid=e99d92&_nc_ohc=f0mJiriDbEEQ7kNvgGQawuT&_nc_zt=24&_nc_ht=scontent.fmnl37-2.fna&_nc_gid=Ap5VuerS1ePRM8xdeios12F&oh=00_AYACqd4iW2N4tMu_q7A9p1UoP8GsorZDSshIkAsXs_vwiQ&oe=678CD5CC",
    review_text: "Super bilis at dali kausap. Top notch service!!!",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "October 12, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Delcalma Tristan Jacob",
    image_link: "Delcalma Tristan Jacob",
    review_text:
      "sir jhun gave me the best customer service and he answered all my questions and requests. 100% would recommend  to buy here. i asked, bought, gave payment and received my pc on the same day",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "October 9, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Ian Jarius Jocson",
    image_link: "Ian Jarius Jocson",
    review_text: `Bought and delivered on the same day.
Budget friendly but quality unit! Great customer service! Shoutout to sir Jhun! 
Thank you and until next deal PC WORTH 🫡🫡🫡🫡`,
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Ana Ampo",
    image_link:
      "highly recommended smooth transaction and all working ang parts❤️",
    review_text:
      "highly recommended smooth transaction and all working ang parts❤️",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "October 6, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Gerard Rio Casilla",
    image_link:
      "https://scontent.fmnl3-3.fna.fbcdn.net/v/t39.30808-1/445046100_8333085646705656_7768538735683995510_n.jpg?stp=cp0_dst-jpg_p40x40_tt6&_nc_cat=111&ccb=1-7&_nc_sid=e99d92&_nc_ohc=OzEhNpUJ7jYQ7kNvgHk3voK&_nc_zt=24&_nc_ht=scontent.fmnl3-3.fna&_nc_gid=Av8SiHgnW3LBIzPQe_la7t3&oh=00_AYC-7jLWV5uL6pMCOsKH7LvOTqrpB0AP9GG-h5v2noKXsw&oe=678CEF33",
    review_text:
      "Good service atsaka affordable sila. Super accommodating ng mga tao sa store. Shoutout din kay Sir Kamit sa naginstall nung mga parts at naginstall ng OS pati drivers! Good job!",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "September 28, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Nathan Dumo ",
    image_link:
      "https://scontent.fmnl37-2.fna.fbcdn.net/v/t39.30808-1/448305164_8077087332309885_2592771670604499403_n.jpg?stp=cp0_dst-jpg_s40x40_tt6&_nc_cat=108&ccb=1-7&_nc_sid=e99d92&_nc_ohc=JBx-TYYW1ogQ7kNvgHJT1eT&_nc_zt=24&_nc_ht=scontent.fmnl37-2.fna&_nc_gid=AXtR5vjiokRdRHLKkitxdOA&oh=00_AYAVV95yk1ElJDOneA0OybE_1A0cNwL7HpGMY5_qFVRmEg&oe=678CDE9C",
    review_text:
      "Fast transaction at installation. hindi magulo ung area unlike gilmore na may mangungulit habang naglalakad ka. 10/10 customer service at legit items",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "September 21, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "瑞妍",
    image_link:
      "https://scontent.fmnl37-2.fna.fbcdn.net/v/t39.30808-1/471609494_122132917862529622_7981200817412562759_n.jpg?stp=c0.0.736.736a_cp0_dst-jpg_s24x24_tt6&_nc_cat=108&ccb=1-7&_nc_sid=1d2534&_nc_ohc=gqtM1uiTkNEQ7kNvgEUVpVt&_nc_zt=24&_nc_ht=scontent.fmnl37-2.fna&_nc_gid=AXtR5vjiokRdRHLKkitxdOA&oh=00_AYBS3XGI2926sNR6DHs0JgREdsZEQliTGNl_cszWksZrbw&oe=678CE9FD",
    review_text: `My rate here is 100% because the staffs here are very helpful
also kind and easy to talk to. really helped
they me This is the #1 cheapest computer store no doubt. 
worth 26K you have a brand new gaming pc.`,
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "September 18, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Tyron Dane Vela",
    image_link:
      "https://scontent.fmnl37-1.fna.fbcdn.net/v/t1.30497-1/453178253_471506465671661_2781666950760530985_n.png?stp=cp0_dst-png_s40x40&_nc_cat=1&ccb=1-7&_nc_sid=136b72&_nc_ohc=_xb90If6N7QQ7kNvgEw6PdW&_nc_zt=24&_nc_ht=scontent.fmnl37-1.fna&_nc_gid=A-BbnvlHi7gtGawKFRgP4Da&oh=00_AYDCl6dBRTpSMK5QkgMkZD3Tn6Uc8MiTHvCeJHjzfCzLvw&oe=67AE943A",
    review_text:
      "Affordable PC parts para sa mga naghahanap ng glow up sa set up nila! Very assuring and responsive ng customer support nila. I ordered 32GB DDR4 RAM kit from them through their website before mag open yung physical store nila (10AM open nila) and mga 7 palang may response na!",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "September 15, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Jeunn Keizer Siguenza",
    image_link:
      "Amazing customer service both online and in-store. Even if the store is full, they’ll be able to accommodate you. And lastly, BEST price in the market!",
    review_text:
      "Amazing customer service both online and in-store. Even if the store is full, they’ll be able to accommodate you. And lastly, BEST price in the market!",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "September 7, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Alexis de Ocampo",
    image_link:
      "https://scontent.fmnl3-4.fna.fbcdn.net/v/t39.30808-1/470106875_9518651784846516_3534786034645018195_n.jpg?stp=cp0_dst-jpg_s40x40_tt6&_nc_cat=104&ccb=1-7&_nc_sid=e99d92&_nc_ohc=JyRpw3rGSzMQ7kNvgFdMHJa&_nc_zt=24&_nc_ht=scontent.fmnl3-4.fna&_nc_gid=A-BbnvlHi7gtGawKFRgP4Da&oh=00_AYAk03wSmzYZFxB-lWkG5UtJTXOWYUZC1dgqSKjts892XQ&oe=678CDC19",
    review_text:
      "Just got my system unit Ryzen 5 5600g today, Im very satisfied with the build and the price of components, medyo nagkaron lang ng misunderstanding sa shipping siguro sa many customers and bad weather but no big deal, I still recommend this shop, Thanks PC Worth 🤙",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "September 5, 2024",
  },
  {
    type: "fb",
    review_id: "",
    customer_name: "Dathan Neal",
    image_link:
      "https://scontent.fmnl3-1.fna.fbcdn.net/v/t39.30808-1/467723417_8693112910783012_3324643576344910425_n.jpg?stp=cp0_dst-jpg_s40x40_tt6&_nc_cat=110&ccb=1-7&_nc_sid=e99d92&_nc_ohc=J1NqFVyHsJwQ7kNvgHGDnra&_nc_zt=24&_nc_ht=scontent.fmnl3-1.fna&_nc_gid=AxT7J3XAieGKrLdG0-E30eL&oh=00_AYDqfEqBwq__MIilivVPH4DrMAw7uPy3fN-teZe2p_ZjjA&oe=678CEF34",
    review_text:
      "Got a 4060ti from them. They were very responsive and delivered it within the same day. Was perfectly packaged and was exactly the item I saw on their shop. Plug and play without any issues. Will definitely be buying from them in the future.",
    link: "https://www.facebook.com/PC.WORTH/reviews",
    created_time: "August 24, 2024",
  },
  {
    type: "fb",
    review_id:
      "pfbid02Lq4qBbhd9cAUGjguZN4yKNejhjRNGjhEk1Z9SgR8TTRJYqhsLiNqFPNNh87cYn3wl",
    customer_name: "Ellard Verdida",
    image_link:
      "https://scontent.fmnl8-2.fna.fbcdn.net/v/t39.30808-1/448556802_10233207053979554_4996114296559938431_n.jpg?stp=cp0_dst-jpg_s40x40&_nc_cat=111&ccb=1-7&_nc_sid=0ecb9b&_nc_eui2=AeGNAMAWEFExRDki7V0Ib-DihWhWbWQFpvCFaFZtZAWm8AKeSWGgGDbTW6PmRQGsW3gRyNLlQyIX3FUqeZMtrKa7&_nc_ohc=2ROyRWvnZEUQ7kNvgFnJPE_&_nc_ht=scontent.fmnl8-2.fna&oh=00_AYBWn3O3Ll3v-cjr3EGenWg_xI2W0WvUFYxEi7xiNON0eQ&oe=66CCBDAF",
    review_text:
      "The best and cheapest PC Store in Metro Manila. Accommodating ang mga staff nila and masaya kausap. Safe and fast din ang delivery ng items, and pinaka best is yung kachi points nila na pwedeng maging discount on your next items.",
    link: "https://www.facebook.com/drallellard/posts/pfbid02Lq4qBbhd9cAUGjguZN4yKNejhjRNGjhEk1Z9SgR8TTRJYqhsLiNqFPNNh87cYn3wl",
    created_time: "Aug 21, 2024",
  },
  {
    type: "fb",
    review_id:
      "pfbid0rAzrHCPaHvaqvCZjzT632rLgcqtt2ciK1qQwgnJTgCwv36VH8aRLijSnZVPhHXnWl",
    customer_name: "Ryan Magsaysay",
    image_link:
      "https://scontent.fmnl8-3.fna.fbcdn.net/v/t31.18172-1/13161760_10207874626118189_9036835972517577826_o.jpg?stp=cp0_dst-jpg_s40x40&_nc_cat=101&ccb=1-7&_nc_sid=e4545e&_nc_eui2=AeGKR-bpYUOTxiq-jIKkz9lSQi5q8O3qLZ9CLmrw7eotn2pQu11dHsLI1m7LjG_-gkyjNcQcoCjrqebMcH4yIpeM&_nc_ohc=cbEb0OSUU6IQ7kNvgFsbPc6&_nc_ht=scontent.fmnl8-3.fna&oh=00_AYD16F6SC47L9OOUhDQ2ECIm0ekQJAXEMjGu8PzxEbDb0g&oe=66EE5192",
    review_text:
      "A very pleasant first time dealing with them, and I couldn't recommend this business enough. Not only do they have some of the best prices for everything, Ramil--and also Sonny (the people who assisted me) were extremely patient and knowledgeable. In the literal hours of our back and forth due to me being fickle and changing my mind over parts, he remained understanding and patient throughout. In the end, I even went with one of his suggested gpus. Kung lahat ng employees nila ganito (and I have a feeling most of them are), tuloy sigurado paglaki ng business na 'to. Hindi ka malulungkot na maghihiwalay kayo ng pera mo, kasi napakaganda ng shopping experience mo.",
    link: "https://www.facebook.com/rmagsaysay/posts/pfbid0rAzrHCPaHvaqvCZjzT632rLgcqtt2ciK1qQwgnJTgCwv36VH8aRLijSnZVPhHXnWl",
    created_time: "Aug 20, 2024",
  },
  {
    type: "fb",
    review_id:
      "pfbid02mYtByn8zqcAuKC6FNGpq1PPWHYT7Z4PodXFaD9SaUhHvfp39Hh4LehKAayFqKoZfl",
    customer_name: "Pat Endaya-Catindig",
    review_text:
      "Beware of this company because they will OVERDELIVER and EXCEED all expectations! They will change your perspective of what an excellent customer service should look like.",
    link: "https://www.facebook.com/pjlendaya/posts/pfbid02mYtByn8zqcAuKC6FNGpq1PPWHYT7Z4PodXFaD9SaUhHvfp39Hh4LehKAayFqKoZfl",
    created_time: "a day ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0KDMxj3vMKq7cyDepzEPxQAHaXanX1To5jkgsBnKxqGzCPDE9kbqSNta3a2ptzy5cl",
    customer_name: "Maria Ziah Lynn Achas",
    review_text:
      "AMAZING CUSTOMER SERVICE! I literally just ordered today and got it in less than 3 hours and I'm all the way in Marikina! Not to mention the free bag and mousepad. \n I would definitely buy from them again especially once we start on building our own PC.",
    link: "https://www.facebook.com/mariaziahlynn.achas/posts/pfbid0KDMxj3vMKq7cyDepzEPxQAHaXanX1To5jkgsBnKxqGzCPDE9kbqSNta3a2ptzy5cl",
    created_time: "a day ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02r2UaLknPQnXiryqbcyjynQkHjK4BGwyFLU626a5GrQmfM98L9YqJVjCahsumoFcQl",
    customer_name: "John Deniel Quiachon",
    review_text:
      "superb customer service! back and forth comms with them kahit walang kasiguraduhan on sellers side. Kudos din sa manager nila sa main branch since he arranged one of the parts to be acquired kahit nasa kabilang branch pa.",
    link: "https://www.facebook.com/sianonson/posts/pfbid02r2UaLknPQnXiryqbcyjynQkHjK4BGwyFLU626a5GrQmfM98L9YqJVjCahsumoFcQl",
    created_time: "a day ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02uNm4v4pM9DZkmuHSX5Z53JhhhJUwXdJZMCdraNTT6MTm6XSHCLjPqsBT6hymrhLGl",
    customer_name: "Mark Evangelista",
    review_text:
      "Bought my pc  and monitor dito sobrang bilis ng delivery at ang responsive nila pag dating sa inquiries. Salamat kay manong rider n ngdeliver. Mabuhay kayo guys",
    link: "https://www.facebook.com/mark.evangelista.528/posts/pfbid02uNm4v4pM9DZkmuHSX5Z53JhhhJUwXdJZMCdraNTT6MTm6XSHCLjPqsBT6hymrhLGl",
    created_time: "a week ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02wHHEsvyAp6fWFvkUA9BJVsxZKgb3jsbpTsoh2Zb9GY4QR6dJFLRHPWzCaYpUFkNEl",
    customer_name: "John Vinceson",
    review_text:
      "Thankyou sir ramil and sa lahat po ng staff na sobrang bait, inassist talaga nila ako hanggang sa pag pickup! wala akong masabe sa store nila basta 1M/10",
    link: "https://www.facebook.com/showmyriceeeee/posts/pfbid02wHHEsvyAp6fWFvkUA9BJVsxZKgb3jsbpTsoh2Zb9GY4QR6dJFLRHPWzCaYpUFkNEl",
    created_time: "a week ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid035HrtmKFXwB1FFr73TxhBeLYqUUGAp3Rb46jFeR5wp9DmZM9gqLhFoizByKNQ1K7yl",
    customer_name: "Paul Baes",
    review_text:
      "Thank you, sobrang flexible nila pag dating sa orders, goods ang Items thank you sir ramil! \n eeeeeyyy",
    link: "https://www.facebook.com/paul.baes.96/posts/pfbid035HrtmKFXwB1FFr73TxhBeLYqUUGAp3Rb46jFeR5wp9DmZM9gqLhFoizByKNQ1K7yl",
    created_time: "a week ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid033zPyFGjGRwTkpSYLc1nurki5NAUKV6pVMUKCJSSMw292G31VQxgGxPmhLpcSAPECl",
    customer_name: "Reynald",
    review_text:
      "Thank you so much sa pag assist from facebook up until sa actual build and pag tulong sa pagsakay ng unit and monitor sa sasakayn, Thank you so much Ramil and PC worth!",
    link: "https://www.facebook.com/Inadzxc/posts/pfbid033zPyFGjGRwTkpSYLc1nurki5NAUKV6pVMUKCJSSMw292G31VQxgGxPmhLpcSAPECl",
    created_time: "2 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02dQmUgQnBf4jLJ89aVu7cEPGWRQf9moBFHqNz7JSBJdipS4L8Y9xYwXvpjepybbKKl",
    customer_name: "Kyle Earl C Ilao",
    review_text:
      "Sobrang flawless ng transaction and below market price mga products nila",
    link: "https://www.facebook.com/TypeItNow/posts/pfbid02dQmUgQnBf4jLJ89aVu7cEPGWRQf9moBFHqNz7JSBJdipS4L8Y9xYwXvpjepybbKKl",
    created_time: "2 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02rvgGTAGTVWUzwZa3Hf2AeG1woiaG9rzRZe8u1isfLy5ffLMjkfSBt9FQvxS3Npctl",
    customer_name: "Rey Arnold Gran",
    review_text:
      "Tried their AI builder on the website, reached out to the Facebook Messenger support, sought assistance and asked if what I selected was right. Support was fast.",
    link: "https://www.facebook.com/reyarnoldgran/posts/pfbid02rvgGTAGTVWUzwZa3Hf2AeG1woiaG9rzRZe8u1isfLy5ffLMjkfSBt9FQvxS3Npctl",
    created_time: "2 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02rGLzXFzfUXEi8DrWSAYy3dgHZyNi3KR1M1qaHhYSeJgGzij5Lzm9uA7wccM3jpHBl",
    customer_name: "Vincent Lim",
    review_text:
      "Smooth transaction. Sonny was very responsive and helpful. Answered all my questions about parts and made sure I had everything I needed for my setup. Did not make it a chore to get a follow-up and was present till the day of shipping. Highly recommend getting all your PC needs from PC WORTH.",
    link: "https://www.facebook.com/GG.Vinci/posts/pfbid02rGLzXFzfUXEi8DrWSAYy3dgHZyNi3KR1M1qaHhYSeJgGzij5Lzm9uA7wccM3jpHBl",
    created_time: "2 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02EGuMbTzSctiZEuxq7F95aHPX2Dr5uGbPdrb3JH58QVt6RDhvyXEJnwkDceibJiMhl",
    customer_name: "RB Bayona",
    review_text:
      "Highly Recommended. Excellent yung customer service wala akong masabi, the best shop so far na nabilhan ko ng pc",
    link: "https://www.facebook.com/ramnel.bayona/posts/pfbid02EGuMbTzSctiZEuxq7F95aHPX2Dr5uGbPdrb3JH58QVt6RDhvyXEJnwkDceibJiMhl",
    created_time: "2 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02vcHxepAjDs5Pv48zCBstDsJHK9Mz6RQ9aSeodUgxGpHcv4SSScLHtGY84gErcRdSl",
    customer_name: "Carlos Abad Martin",
    review_text:
      "Thank you, Sir Marshall, and the rest of the staff for answering my questions and building my PC. I highly recommend pc worth for a smooth transaction.",
    link: "https://www.facebook.com/ccarlos.10/posts/pfbid02vcHxepAjDs5Pv48zCBstDsJHK9Mz6RQ9aSeodUgxGpHcv4SSScLHtGY84gErcRdSl",
    created_time: "2 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02PFwkxPqBsMQ9WgyQ9vehJ9PvEwYi7tVHDvz2iVss8UcezHhW16nti3u9JsakRpTdl",
    customer_name: "Jethro Lapuz Camara",
    review_text:
      "From building the customized specifications, providing the payment options, assembling the build, to delivering it on my doorstep, PC Worth processed the PC I desired in one day. Marshall, a staff in PC Worth, made that all possible.",
    link: "https://www.facebook.com/JethroElLapuzCamara/posts/pfbid02PFwkxPqBsMQ9WgyQ9vehJ9PvEwYi7tVHDvz2iVss8UcezHhW16nti3u9JsakRpTdl",
    created_time: "2 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02JSKovXkYkrLvuC8wk6NbZxVnnWGM9McZmZrZJ5SpR9aUtWDTLmKXRbbVnDKF5uvl",
    customer_name: "Spencer",
    review_text:
      "Thank you po mga bossing sa assistance kahit bad weather and late na po nag start pag bbuild ng PC, commendable customer service po kay Sir Ken and team sa pag OT para po ma build yung 4 units.",
    link: "https://www.facebook.com/guzman.spencer/posts/pfbid02JSKovXkYkrLvuC8wk6NbZxVnnWGM9McZmZrZJ5SpR9aUtWDTLmKXRbbVnDKF5uvl",
    created_time: "2 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02WGKGNXCaLJ5tXdSgASWovD4zkUV8wQRB38dXkQ5QRk6xYbRsfCpjh99D2r7DiWMyl",
    customer_name: "Aaron Andee Ayo",
    review_text: "Fast service and more options for payment",
    link: "https://www.facebook.com/aaronandeeayo1993/posts/pfbid02WGKGNXCaLJ5tXdSgASWovD4zkUV8wQRB38dXkQ5QRk6xYbRsfCpjh99D2r7DiWMyl",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02zbGmHTS4ixXkkecn4S3Q58qyiGMGi5AAjUHi3tfDAcbJPtdfuQYzJQ2Ub2e8K3ojl",
    customer_name: "Seth Floreven",
    review_text:
      "Thank you to Sonny for the fast replies and answering my many questions. \n I had problems with the PC they sent me so I went to their branch and they tested it right away. It was caused by a faulty insertion of the PSU cable to the GPU, but they fixed it and tested it for 4 hours to make sure it was good. Even so, they assured me that if there's any other problems, I could contact them at any time. Kudos to the technicians, very professional and kind to their clients' queries! :)",
    link: "https://www.facebook.com/sethfloreven.biagtan/posts/pfbid02zbGmHTS4ixXkkecn4S3Q58qyiGMGi5AAjUHi3tfDAcbJPtdfuQYzJQ2Ub2e8K3ojl",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02c5Su6A4rD7acd1TRZX3w2yvsHj7VsfKYMjyDDtZrCgZTCTf27qUmkRxD7nHjVB9El",
    customer_name: "Ric Boongaling",
    review_text:
      "Highly recommended. One of the cheapest pc stores in PH. Smooth transaction and same day delivery. Salamat Sir Marshall for the assistance.",
    link: "https://www.facebook.com/ric.boongaling/posts/pfbid02c5Su6A4rD7acd1TRZX3w2yvsHj7VsfKYMjyDDtZrCgZTCTf27qUmkRxD7nHjVB9El",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02L3rHDp886Aac9Y7kMSKiNsu2YTFqUDnNpScz7uAboyYMDXJv2PLh7EsukALVi3c1l",
    customer_name: "Steven John Nino Duran",
    review_text:
      "presyong on sale na sa iba. mas mura parin sa PC Worth.. worth it talaga. pag may gusto ako bilhin , dito muna ako tumitingin",
    link: "https://www.facebook.com/benben.duran/posts/pfbid02L3rHDp886Aac9Y7kMSKiNsu2YTFqUDnNpScz7uAboyYMDXJv2PLh7EsukALVi3c1l",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid09SztwFWEe2M6HptXouBabVpQhkX2CZkZcKZzrKvCrwmDLWTP7FazzRuy8yXYhRp8l",
    customer_name: "Aaron Alonzo",
    review_text:
      "highly recommended! quality service. fast and smooth transactions. salamat sir sonny! sa uulitin po!",
    link: "https://www.facebook.com/aarn.alnz/posts/pfbid09SztwFWEe2M6HptXouBabVpQhkX2CZkZcKZzrKvCrwmDLWTP7FazzRuy8yXYhRp8l",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02DP8x9QFGa3KjKEJDpNf6yT6DUemcghTd7mBrwwC4PMBQLf1iTtuTgXhAG7gUtRG4l",
    customer_name: "Jonathan Hernandez",
    review_text:
      "I highly recommend PC Worth. They are legitimate and very accommodating especially Sir Ramil for addressing my inquiries and concerns online, thank you Sir Ramil. I would also like to thank Sir Josh for the quality build of my PC. Maraming Salamat PC WORTH!!!",
    link: "https://www.facebook.com/permalink.php?story_fbid=pfbid02DP8x9QFGa3KjKEJDpNf6yT6DUemcghTd7mBrwwC4PMBQLf1iTtuTgXhAG7gUtRG4l&id=100041071843163&comment_id=800609228917158&__cft__[0]=AZUGyrr2eKsj-D3E_HL4yexjtfng4kW_c2THAfa7Q5CEBeCKGaDq7SjmRM2cMQVlIHkWtLSxdtJtpJ2LpmA16wgQ2wY_7I4FP9oUUnsss_2_pbHPTTvR8aw3gVLHm9QVFnbm7V5fNSUVH1ctW3SmnAdW&__tn__=R]-R",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02BMJN6itSrqK6a5Nf1kP1RNPQ7nRQDd4HQPyxbqLBLsRgmUoRQZEcxoiTBky3TR6rl",
    customer_name: "Renzo Cabarios",
    review_text: "Malupet si pareng Marshal. Naging malakas na programmer ako",
    link: "https://www.facebook.com/renzo.cabarios.33/posts/pfbid02BMJN6itSrqK6a5Nf1kP1RNPQ7nRQDd4HQPyxbqLBLsRgmUoRQZEcxoiTBky3TR6rl",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02RtjHVvhtf9nXCKLrnEtvpssDdwde5iHJiv2ABYHuEtrdX8Q15X7gh6BmSt8k3Wppl",
    customer_name: "Giean Mae Evangelista",
    review_text:
      "Very accommodating and responsive po sila. Thank you to Sir Sonny and PC Worth for my PC Build",
    link: "https://www.facebook.com/evangelistagieanmae/posts/pfbid02RtjHVvhtf9nXCKLrnEtvpssDdwde5iHJiv2ABYHuEtrdX8Q15X7gh6BmSt8k3Wppl",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0YVBfYtgoEu6TbPbbt6trx4ZD2pTyVpspzeJKi3KCCSo5c6XpoMEys1fVMxUCscMFl",
    customer_name: "Ken Lee",
    review_text: "smooth and fast transaction thanks to sir Ramil",
    link: "https://www.facebook.com/bastaken2/posts/pfbid0YVBfYtgoEu6TbPbbt6trx4ZD2pTyVpspzeJKi3KCCSo5c6XpoMEys1fVMxUCscMFl",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0yUQKNJ26hrVp9AtU66TTdsGAp7G4eKj5Jbgc1TsjXb3WunwiikxtnxrSN53tHq3jl",
    customer_name: "Irene Arevalo",
    review_text:
      "I Highly Recommend PC Worth\n100 Percents Legit and Fast Transactions\nand special Thanks to Sir Vincent na nag assist at nag build ng system unit q\nKudos din sa buong staff supperr babait nila",
    link: "https://www.facebook.com/irene.arevalo.188/posts/pfbid0yUQKNJ26hrVp9AtU66TTdsGAp7G4eKj5Jbgc1TsjXb3WunwiikxtnxrSN53tHq3jl",
    created_time: "4 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02bA3Uu414EgWyBmhw1uJXqp1aVZWJvTMDjMwTHhXmPBswgSXencvccbBWkeMLNNHkl",
    customer_name: "Rajel Hernandez",
    review_text:
      "I highly recommend PC Worth. They are legitimate, responsive, and offer competitive prices. The transaction was smooth, and the quality of the build was excellent. Thank you to Sir Sonny for his assistance and to Sir Vincent for building my PC and addressing all my additional questions.",
    link: "https://www.facebook.com/korsno/posts/pfbid02bA3Uu414EgWyBmhw1uJXqp1aVZWJvTMDjMwTHhXmPBswgSXencvccbBWkeMLNNHkl",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0VqxoUYnzjh2NwY2j8VVohidy4q4avLBifHN3qGwkaR6jGYXtp35WEStTxa5qoEANl",
    customer_name: "Rodelle Avila Garcia",
    review_text:
      "Thank you Sir Sonny for the fast replies and seamless transaction.",
    link: "https://www.facebook.com/delle.hatred/posts/pfbid0VqxoUYnzjh2NwY2j8VVohidy4q4avLBifHN3qGwkaR6jGYXtp35WEStTxa5qoEANl",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02VbYdUaGam9fcyEqMWpA8tkSG2KmkWRWTq4DnVgNkdJhWL8s8qJhFbwJfHUSPx2a2l",
    customer_name: "Glenn Cavita",
    review_text:
      "Highly Recommended, Smooth Transaction with Sir Sonny. Very accommodating\n!",
    link: "https://www.facebook.com/glenn.cavita/posts/pfbid02VbYdUaGam9fcyEqMWpA8tkSG2KmkWRWTq4DnVgNkdJhWL8s8qJhFbwJfHUSPx2a2l",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0319hzYQx2cHh1bD58hnfLK5jhQbxPtt1QawCv1WdfvYZNderUayuFoGutbUVq4hzyl",
    customer_name: "Mark Anthony de Jesus",
    review_text:
      "Highly recommended ang PC Worth! Sobrang smooth and fast transaction. In 1 day nadeliver na agad sa bahay ung unit! And to Sir Ramil, salamat din sa pag accommodate ng mga tanong ko at sa pag assist nun pag punta namin dyan.",
    link: "https://www.facebook.com/markkoolit/posts/pfbid0319hzYQx2cHh1bD58hnfLK5jhQbxPtt1QawCv1WdfvYZNderUayuFoGutbUVq4hzyl",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0cNp6RbyBFXv9pRj2yt85NT7xGLSGV6BLysepnVfeuoZfSjUqZMvaAfZxoCgwFpjcl",
    customer_name: "Yves Domingo",
    review_text: "Thanks PC WORTH and boss Sonny for the smooth transaction!",
    link: "https://www.facebook.com/besoyyy/posts/pfbid0cNp6RbyBFXv9pRj2yt85NT7xGLSGV6BLysepnVfeuoZfSjUqZMvaAfZxoCgwFpjcl",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid014MmAAQVpggSDHqc5qSAmQKxSjmMZFoDnRGWR8VgzLBkCXpSiE49kR2YVVQVXNUpl",
    customer_name: "Alfie Deada",
    review_text:
      "Thankyou pc worth sa pc build na gnwa ntin solid po tlga tska delivery kahit naulan thankyou kuya carlo sana nabusog kta hahaha",
    link: "https://www.facebook.com/A.Deada12/posts/pfbid014MmAAQVpggSDHqc5qSAmQKxSjmMZFoDnRGWR8VgzLBkCXpSiE49kR2YVVQVXNUpl",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0pnQ3iyvCHDL5MzAR5ToyKrtDWYvY13Ta5mQFZJFnuMkYJcCyjodZGNKsRfrAr9mml",
    customer_name: "Monz Ganzon Caro",
    review_text:
      "Sulit na sulit ung desktop lalo na ung pag accommodate ni sir Ramil sa mga questions and follow up messages ko. PC WORTH worth it talaga!",
    link: "https://www.facebook.com/mc1989/posts/pfbid0pnQ3iyvCHDL5MzAR5ToyKrtDWYvY13Ta5mQFZJFnuMkYJcCyjodZGNKsRfrAr9mml",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0RzQhSTDEXQeZgPJwuJ1uug9YFL4cAwwqQiBekeFcLJotcbYxb9DpqShYPaGbkkxSl",
    customer_name: "JJ Nueros",
    review_text:
      "Struggled to fix my wife's pc. kahit na malapit na sila mag close inaccommodate padin nila ako and napakabilis ng serbisyo. keep it up PC WORTH!!",
    link: "https://www.facebook.com/SoShiJuerjen/posts/pfbid0RzQhSTDEXQeZgPJwuJ1uug9YFL4cAwwqQiBekeFcLJotcbYxb9DpqShYPaGbkkxSl",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0NRg7E38xxeYwvJkKkqfV1Ewud3jZcGbbtnahcd65bFzYobnHL6AEYjDPgkewDt9fl",
    customer_name: "Richard Alferez",
    review_text:
      "Thank u so much pc worth, very accommodating lahat ng nagassist samin  lalo na si sir Ramil na nagasikaso ng questions ko sa chat and sa transaction maraming salamat. Recommended tong shop 10/10 hindi ka madidisappoint dahil masasagot lahat ng questions and kailangan mo. Will definitely come back if ever kailanganin ulit ng item.",
    link: "https://www.facebook.com/richard.alferez.549/posts/pfbid0NRg7E38xxeYwvJkKkqfV1Ewud3jZcGbbtnahcd65bFzYobnHL6AEYjDPgkewDt9fl",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0e9aZeKB6rBa9b2T4Xe4U4W3xCdhm2RgSTMKEZyEKDEHeWdk7iLfKsjsMuiQG22Bzl",
    customer_name: "Drew L Hermosilla",
    review_text:
      "Thank you PC WORTH for legit, hassle-free and safe transaction. Salamat kay bossing Ramil sa pagtulong at pagsagot sa lahat ng mga inquiries ko. Ordered from their website umaga, naideliver din agad same day, assembled na ang PC set. Sinagot na nila ang pagassemble, free of charge.",
    link: "https://www.facebook.com/dr3wh3rm0s1ll4/posts/pfbid0e9aZeKB6rBa9b2T4Xe4U4W3xCdhm2RgSTMKEZyEKDEHeWdk7iLfKsjsMuiQG22Bzl",
    created_time: "3 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02UK4iQMYHcYs4dRVXRSVsdwY9opGvrd6E9LmkNekB5yRQ6haGyvVdGecJQsnkosA4l",
    customer_name: "あルバ クゲイ",
    review_text:
      "Another purchase from my no.1 trusted store that offers cheapest price in the market. Super sulit! Super solid!",
    link: "https://www.facebook.com/AdollaBurstPillar1/posts/pfbid02UK4iQMYHcYs4dRVXRSVsdwY9opGvrd6E9LmkNekB5yRQ6haGyvVdGecJQsnkosA4l",
    created_time: "5 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0scDKvz28qDyx4kGJ9f7ZSn9NU4mK9RE8Cw2H8CmkyPS48k1RkVnALa9RXj7D3WtPl",
    customer_name: "Bono Fernandez",
    review_text: "10/10 I highly recommend sa mga  gustong mag pa build ng PC.",
    link: "https://www.facebook.com/permalink.php?story_fbid=pfbid0scDKvz28qDyx4kGJ9f7ZSn9NU4mK9RE8Cw2H8CmkyPS48k1RkVnALa9RXj7D3WtPl&id=100009250565760&comment_id=7768030036658616&__cft__[0]=AZWo6JVUeSz99bDYAYI7Y-k9Wa7_t6iGES4wrJf45Cm79UiUQeGKuH7m4wZusRASy-TpfwlXYbH7RBCxo5ZBxUQiwBCOw719BpNp5Tq2Hb58pQ&__tn__=R]-R",
    created_time: "5 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02sSpXYLDMZV6Lpj6ominzYHd4cvtn1XhGg178GW5thrvdiHgN5D37ejZP9tESmabfl",
    customer_name: "Lo Mariñas",
    review_text:
      "Got my pc built before sa branch nila sa earnshaw. Asked if they can help me with my system unit thru chat at first but I cant seem to find the problem so I brought it sa store mismo and helped me fix my pc without any charge. Thanks! Forgot to ask the name of the tech thanks kuya",
    link: "https://www.facebook.com/Lomrns/posts/pfbid02sSpXYLDMZV6Lpj6ominzYHd4cvtn1XhGg178GW5thrvdiHgN5D37ejZP9tESmabfl",
    created_time: "5 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02rAEH5ujHVAtMSfSmeJZ72YL8avbxmmQzLMgoqZzGNfsZ28nETgFGXbFccgdw1UUjl",
    customer_name: "Drofflic San Diego",
    review_text:
      "If you are looking to build your dream PC build, PC WORTH is one of the best if not the best PC build store. Top of the line ang Customer Experience na binibigay ng mga friendly staff. KUDOS kay Sir RAMIL and sa ibang nag-assist sa pagbuild ng Dream PC setup ko.. HIGHLY RECOMMENDED!!",
    link: "https://www.facebook.com/cliff.sandiego.5/posts/pfbid02rAEH5ujHVAtMSfSmeJZ72YL8avbxmmQzLMgoqZzGNfsZ28nETgFGXbFccgdw1UUjl",
    created_time: "5 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid09ZScXD2wQzr6GRNmWZ3EL8FLBVXMPTFWFVnkGsy6NPLqZY9oLSTecyxRNzvMwcmrl",
    customer_name: "Joshua Realosa",
    review_text: "SOBRANG RECOMMEND",
    link: "https://www.facebook.com/xua.aaa/posts/pfbid09ZScXD2wQzr6GRNmWZ3EL8FLBVXMPTFWFVnkGsy6NPLqZY9oLSTecyxRNzvMwcmrl",
    created_time: "5 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02q23m76MrtnfwHgddVHXHrG7kCRwVNauNrEns5WqP66wusmCU3jhPfhSWFFiNN6Tgl",
    customer_name: "Robiro Rigu",
    review_text:
      "10/10 for after-service! Plus, PCWorth even covered the SF when I bought a GPU. 100% legit.",
    link: "https://www.facebook.com/07Nayu/posts/pfbid02q23m76MrtnfwHgddVHXHrG7kCRwVNauNrEns5WqP66wusmCU3jhPfhSWFFiNN6Tgl",
    created_time: "5 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0etVu5xnXhJRbTx4dmRqro7sn1giWmpkEzRDA8nEuZdwk9FJcei7sVJDukbkDxfgAl",
    customer_name: "Jack Sawn",
    review_text:
      "Sobrang swabe katransaksyon, hinding hindi ka magsisi! Salamat din kay Idol TPC nga pala sakanya ko nalaman yung store. Hahaha \nmore customers to come PC WORTH",
    link: "https://www.facebook.com/Jcksn0929/posts/pfbid0etVu5xnXhJRbTx4dmRqro7sn1giWmpkEzRDA8nEuZdwk9FJcei7sVJDukbkDxfgAl",
    created_time: "5 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid037MSoW13efJYnoEWFdN7tATxZM3TA82q9Jvth1eMeaK6DJnVK5y9K1vpbdSfxZYodl",
    customer_name: "Patrick Allan Sara",
    review_text:
      "Napaka solid ng staff kudos kay Sir sonny salamat sa fast transaction thru online gang sa pag pickup ng GPU sa physical store nila and thankyou din sa mga suggestions malaking tulong sa uulitin thankyou din PC Worth sa mga best deals nyo",
    link: "https://www.facebook.com/allansara/posts/pfbid037MSoW13efJYnoEWFdN7tATxZM3TA82q9Jvth1eMeaK6DJnVK5y9K1vpbdSfxZYodl",
    created_time: "5 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid06cCcHRcKjEDktryhJMEk7mnNSuAgZpon2XYv3DUiZAjUm1pRmUqMSJKy8j7iEJwkl",
    customer_name: "Patrick Del Gallego",
    review_text:
      "Mabilis na transaction. As a techy myself, alam kong best PC parts nirerecommend ni seller (Sonny). Maayos rin ang assembly for a small form factor PC! Ndi lahat ng builders kaya/magaling mag assemble ng small form factor (Kenneth).",
    link: "https://www.facebook.com/neil.dg/posts/pfbid06cCcHRcKjEDktryhJMEk7mnNSuAgZpon2XYv3DUiZAjUm1pRmUqMSJKy8j7iEJwkl",
    created_time: "5 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid03fPwaTCokxFcWjuyNWewqgM12wMv1V5Zj9TenauiHLnKKjHqPw4conS8VyQN8dsJl",
    customer_name: "Dharyl Pomarejos",
    review_text:
      "Solid PC nila rito super ganda, and yung service nila napaka bait. Thank you kay Sir Ramil sa nag assist sa'kin.",
    link: "https://www.facebook.com/dharyl.pomarejos/posts/pfbid03fPwaTCokxFcWjuyNWewqgM12wMv1V5Zj9TenauiHLnKKjHqPw4conS8VyQN8dsJl",
    created_time: "5 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02QcFNRhybWbpUr4aQ8fSL45QfZ44Pj8YXS1xZgvhhd7VFpSLpzYp7XNmwdB94wutUl",
    customer_name: "Regine Clarisse Antonio",
    review_text: "Fast and smooth transaction! Legit store",
    link: "https://www.facebook.com/itsmereghiin17/posts/pfbid02QcFNRhybWbpUr4aQ8fSL45QfZ44Pj8YXS1xZgvhhd7VFpSLpzYp7XNmwdB94wutUl",
    created_time: "5 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02FPLXT3HUMg2AWPySCRqWPURjkYFdVnRaRxipdAzB3oMs9r6LMg9WGkqLT6QJM2F2l",
    customer_name: "Jonel Relova",
    review_text: "Amazing Customer Service!!",
    link: "https://www.facebook.com/jonel.relova/posts/pfbid02FPLXT3HUMg2AWPySCRqWPURjkYFdVnRaRxipdAzB3oMs9r6LMg9WGkqLT6QJM2F2l",
    created_time: "5 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02ZBNcPVAoHjVfME7whB2793zFCqZbKyLfs3DbZDEsLUrnx9gY1rjbb4XcxkLZc7CDl",
    customer_name: "John Aries Borja Elacion",
    review_text:
      "Solid services from Sir Ramil at Sonny, mabilis ang transaction, fairly priced rin, would definitely recommend of you're planning on building your own PC.",
    link: "https://www.facebook.com/ejohnaries/posts/pfbid02ZBNcPVAoHjVfME7whB2793zFCqZbKyLfs3DbZDEsLUrnx9gY1rjbb4XcxkLZc7CDl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0XQHB49xcVx7UUqPRCUjpxeoH2TUjX9JCpPXRqfSB61QEJYEpwevkr4cerLdaZqXCl",
    customer_name: "Fred Manucdoc",
    review_text:
      "PC Worth is worth it talaga mga Sir! Sobrang competitive ng prices po nila, and sobrang approachable po ng staff nila. Very responsive din po sila sa questions thru their page po. Thank you again PC Worth and thank you Sir PJ and Sir Ramil  for answering my queries and for assisting sa pag build ng 1st PC ko.",
    link: "https://www.facebook.com/fredmanucdoc/posts/pfbid0XQHB49xcVx7UUqPRCUjpxeoH2TUjX9JCpPXRqfSB61QEJYEpwevkr4cerLdaZqXCl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02MXFcaJKt5CGVT48BBJswhkuFhxfzZ6nYieEsLrPvxZagquANDJNQpXJnCeMn2wevl",
    customer_name: "Mel CO",
    review_text:
      "Competitive price. Very responsive even after 8pm . Fast service. Thank you Ken, Ramil, and kenneth",
    link: "https://www.facebook.com/melvin.ongco/posts/pfbid02MXFcaJKt5CGVT48BBJswhkuFhxfzZ6nYieEsLrPvxZagquANDJNQpXJnCeMn2wevl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02kxvFvkR67Ttihhfj3ruDrSTixsbxtgfuXV3w79c1WaejpWkmymUAzW4tFTERCzZsl",
    customer_name: "John Lloyd Colico",
    review_text: "Solid accomodation thru chat w marshall and sa pag deliver..",
    link: "https://www.facebook.com/colicxofficial/posts/pfbid02kxvFvkR67Ttihhfj3ruDrSTixsbxtgfuXV3w79c1WaejpWkmymUAzW4tFTERCzZsl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02a9qMs2jBR5Hjgp2gejm23eFT1GUdD2EQYiKNs9Pk2izE7n3o4AtwFtCLXGoWKihcl",
    customer_name: "Joe Peter Ecraela",
    review_text:
      "CHEAPER PRICE kesa sa mall price, LEGIT!! Nakakagulat pa, sa sobrang mura, may DISCOUNT pa!! Brandnew and sealed din yung laptop. Very accomodating and friendly ang staff, they also explain and guided me habang tinetesting yung laptop. Highly recommend dahil sa top notch customer service.",
    link: "https://www.facebook.com/joepeter28/posts/pfbid02a9qMs2jBR5Hjgp2gejm23eFT1GUdD2EQYiKNs9Pk2izE7n3o4AtwFtCLXGoWKihcl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02MuXp6sw1JHLx7qoK1rmNp43qKFtSrybL2iHQ1CUAeCjqfePyQHHja1QimYCSvVYwl",
    customer_name: "Jans Sepe",
    review_text:
      "PC worth talaga! worth it na worth it. Maaasahan , kahit sino sa mga tao dun magagaling. Mabilis din yung serbisyo. not over price at lalong walang hidden charges.",
    link: "https://www.facebook.com/jans.sepe/posts/pfbid02MuXp6sw1JHLx7qoK1rmNp43qKFtSrybL2iHQ1CUAeCjqfePyQHHja1QimYCSvVYwl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid032rS82rsPhQmvd8oMikP1vSCuRaffXadsDokVFF8CGdXNNFwL3nS2B1PuphDJyZkrl",
    customer_name: "Jan Michael",
    review_text: "Fast reply! Thank you guys!",
    link: "https://www.facebook.com/jmbsantos/posts/pfbid032rS82rsPhQmvd8oMikP1vSCuRaffXadsDokVFF8CGdXNNFwL3nS2B1PuphDJyZkrl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02SDBmCBFoZ8xsJzaptSJpiejHmS4XBR5FpVvLbYufYkQRQAQuAnV7r2iNe1pmuX2yl",
    customer_name: "Lee Dump",
    review_text:
      "ganda ng service at ng products napaka angas! Thankyou po pc worth(earnshaw/bustillos) and thankyou kay sir sonny na nagassist po samin, all goods gagamitin nalang agad thankyou po!!",
    link: "https://www.facebook.com/amara.margarette11/posts/pfbid02SDBmCBFoZ8xsJzaptSJpiejHmS4XBR5FpVvLbYufYkQRQAQuAnV7r2iNe1pmuX2yl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0txjcAhg6L82VyVWaUMyZinvRMF9y7NP7ox7QBKiRdzQMDKDZHFpu7xMj4Rx9LVyrl",
    customer_name: "Michael Cu",
    review_text:
      "Kudos sa PC Worth team and to sir ramil to be exact for assisting me sa transaction and build ko, kahit inabot na ng closing hours, just to finish my build. \n More power to PC Worth!!",
    link: "https://www.facebook.com/cumichael14/posts/pfbid0txjcAhg6L82VyVWaUMyZinvRMF9y7NP7ox7QBKiRdzQMDKDZHFpu7xMj4Rx9LVyrl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02sJ5nv7qPHvMLmrRyE5ZSPoLdLPKYyAv5xink5PHiTmPkreyuo5coigYJZzu6xL2ql",
    customer_name: "Deill Haryll Francisco",
    review_text:
      "very accommodating ng staff lalo na si sir marshall and sir pj. highly recommended if you’re looking for a pc part.",
    link: "https://www.facebook.com/deill18/posts/pfbid02sJ5nv7qPHvMLmrRyE5ZSPoLdLPKYyAv5xink5PHiTmPkreyuo5coigYJZzu6xL2ql",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0nEJteAVoQfQPcaZUPRxsHRT15XtRES1SG4A6fjAfT1pjAf11S2gzNzBCJfg6CVaCl",
    customer_name: "Eyahk Chavez",
    review_text:
      "Super legit! very accomodating ang staff! kahit ang kulit ko\n they suggest kung ano ang better na parts \n kudos! I will surely purchase again for upgrades! Thank you!",
    link: "https://www.facebook.com/chubbs.052k17/posts/pfbid0nEJteAVoQfQPcaZUPRxsHRT15XtRES1SG4A6fjAfT1pjAf11S2gzNzBCJfg6CVaCl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02ZsnEbEnJwi369HDU3izTNqXUw6rtyfBxT6uYFTVVF9kc2kF3YuJjzNCsppz2xszil",
    customer_name: "Ronald Rey",
    review_text:
      "Customer service is superb! I was accompanied by a staff named Sonny and napakadali nyang kausap. He gave me everything i asked for. So goods na goods sa cs.",
    link: "https://www.facebook.com/permalink.php?story_fbid=pfbid02ZsnEbEnJwi369HDU3izTNqXUw6rtyfBxT6uYFTVVF9kc2kF3YuJjzNCsppz2xszil&id=100081045337787&comment_id=1495292037737900&__cft__[0]=AZViKK7byMik8kcW7WOg2Hjq2X7VLl316vSi_zG41TFZRSJZO8eD7uszhvZRitotWW4jIhaXfLtM3RJ_MHNN8DsTrDoH5NBnmClRKoptTFajxw&__tn__=R]-R",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0F7Po2fzYFJCYw8N673LXVJ15BJyB9ywJ4gtN8mTVYpSzQipptr5SMHGDGzoTUQaMl",
    customer_name: "Francis Luis C. Marquez",
    review_text:
      "Napaka bait ng customer support lalo na po kay boss Ramil thank you po sa pag assist God bless po.",
    link: "https://www.facebook.com/CHRP15/posts/pfbid0F7Po2fzYFJCYw8N673LXVJ15BJyB9ywJ4gtN8mTVYpSzQipptr5SMHGDGzoTUQaMl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0FjykGZq3FVqHY5jzhgsJxqMAmyXSjhKRhvZsXzJr5Kk4fD62rgceQh85qceUKXykl",
    customer_name: "Norlan Unson Jr.",
    review_text:
      "Grabe customer service dito, kahit lagpas na sa working hours pinulit padin maibuild and madeliver on the same day yung PC. Thank you kay Boss Sonny na nagcoordinate at nagadvice sa build at kay Boss Josh na nagbuild at nagdeliver!",
    link: "https://www.facebook.com/norlanunson/posts/pfbid0FjykGZq3FVqHY5jzhgsJxqMAmyXSjhKRhvZsXzJr5Kk4fD62rgceQh85qceUKXykl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02TMFwnnsTDS1cKyAec661xCM35JuoWUaxc1jjAihouQXMWWWq8H5hMJPB3gRRBfGrl",
    customer_name: "Ace",
    review_text: "Thank you PC WORTH!",
    link: "https://www.facebook.com/aacquile/posts/pfbid02TMFwnnsTDS1cKyAec661xCM35JuoWUaxc1jjAihouQXMWWWq8H5hMJPB3gRRBfGrl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02q6CTeo3JApXMTphxtnCuZSgPmKJT31xmp91swKFNmXSvU6LochH718ohpzYGPje3l",
    customer_name: "JD Agpalo",
    review_text: "I absolutey recommend PCWORTH",
    link: "https://www.facebook.com/permalink.php?story_fbid=pfbid02q6CTeo3JApXMTphxtnCuZSgPmKJT31xmp91swKFNmXSvU6LochH718ohpzYGPje3l&id=100004238384883&comment_id=1000242927978903&__cft__[0]=AZX6YBKCXVAsXYwtAVAXqphyvTQQQVy2gZjqjd6I3uYstHPsf1eq2_6_QBOiA44JyOLyHjarbsHPTD90s9wGizxf9RIGswG4gg1kJfw4PG05og&__tn__=R]-R",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid022KCdTMZ7TGUsWpH1U2SaPWj5hTrtHoPUiNCKohyYKpTDAy41nfq8u3Af5M33evLhl",
    customer_name: "Marc Gab Ferrer",
    review_text:
      'Sobrang solid ng pc worth! ang linis ng build ko lalo na yung cable management at accommodating ang mga "bossing" yan ang tawag nila sa mga customer nila so yan din tawag ko sa kanila.',
    link: "https://www.facebook.com/marcgabferrer/posts/pfbid022KCdTMZ7TGUsWpH1U2SaPWj5hTrtHoPUiNCKohyYKpTDAy41nfq8u3Af5M33evLhl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid06qxiWxhvhqUEy19VpTd1kfNkxW6zURhEhFMNCZs6oHRgoSjpv2fiJtoXKs9sV94nl",
    customer_name: "Rhiennier",
    review_text:
      "First time ko magpabuild sa PC Worth. Nadeliver din ng maayos yung system unit and monitor and super bait ng nagsupport sa akin. Thank you sir ramil.",
    link: "https://www.facebook.com/rhnnrmndl/posts/pfbid06qxiWxhvhqUEy19VpTd1kfNkxW6zURhEhFMNCZs6oHRgoSjpv2fiJtoXKs9sV94nl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid05RBQMLckzsmeoMdV72W7tEh2AMndZwaHBYKCpfJZfggvAxuEL2YRCKTScnhtCwEdl",
    customer_name: "Lander R. Mariano",
    review_text:
      "sobrang sulit and sobrang maasikaso ng mga staff \n thank you pc worth sa bagong PC \n thank sir.ramil for assisting",
    link: "https://www.facebook.com/lander.mariano/posts/pfbid05RBQMLckzsmeoMdV72W7tEh2AMndZwaHBYKCpfJZfggvAxuEL2YRCKTScnhtCwEdl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0eATG9ArQ7fN7SPWZKxPWpzv2eJZX6RFUr4sYkRjE5Y5o86FvPT3JQQ4Ehq22Wxqkl",
    customer_name: "Princess Mafnas",
    review_text:
      "easy and fast transaction! mabait lahat ng staff and accomodating",
    link: "https://www.facebook.com/cesstv026/posts/pfbid0eATG9ArQ7fN7SPWZKxPWpzv2eJZX6RFUr4sYkRjE5Y5o86FvPT3JQQ4Ehq22Wxqkl",
    created_time: "6 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02hJWGNZTSSEGCdYEWN28cJoFRzUeB7SuxBTnZf7A2oVhMVDdUzjpHt8UkSK31SzA6l",
    customer_name: "Ram Fernando III",
    review_text: "This review is for my second purchase with PC WORTH.",
    link: "https://www.facebook.com/ramfernandoIII/posts/pfbid02hJWGNZTSSEGCdYEWN28cJoFRzUeB7SuxBTnZf7A2oVhMVDdUzjpHt8UkSK31SzA6l",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0EkkdgNM8nWRwKmizX77BjHozSjddgeSdLrhFwPdV5LCDfXSBAWiJnMvvoehyBUg6l",
    customer_name: "Centie Herida",
    review_text:
      "I was amazed dahil sobrang bilis ng transaction, competitve yung prices and sobrang linis ng build! Kudos din kay sir Marshall na napaka friendly and ang bilis kausap! I highly recommend PC worth",
    link: "https://www.facebook.com/wewewelei/posts/pfbid0EkkdgNM8nWRwKmizX77BjHozSjddgeSdLrhFwPdV5LCDfXSBAWiJnMvvoehyBUg6l",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02VfhL2Gx23LbB2CW4qJDZHbH5TkHMbPLTZF4cfECrdJUpfp1QHpNUuRFzpS3HxGaDl",
    customer_name: "Glydel Barbas",
    review_text:
      "10/10 sa service! Thank you kay Sir Marshall sa pagassist. Mabait kausap mga staff and mabilis magreply regarding with my concerns. Within the day nakuha ko agad pinabuild ko.",
    link: "https://www.facebook.com/glydeljoy.barbas/posts/pfbid02VfhL2Gx23LbB2CW4qJDZHbH5TkHMbPLTZF4cfECrdJUpfp1QHpNUuRFzpS3HxGaDl",
    created_time: "8 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0rxyNPsezFz9dpNVDD1sLpLaTtuyFGkUdLEq8ZnZNiL9YVt6v1GNEaBVWBMQkrSjsl",
    customer_name: "예규",
    review_text:
      "I am very much satisfied with the unit, as well as the customer service. Special thanks to Sir Sonny of PC Worth for assisting me with my customized needs!",
    link: "https://www.facebook.com/therealxadia/posts/pfbid0rxyNPsezFz9dpNVDD1sLpLaTtuyFGkUdLEq8ZnZNiL9YVt6v1GNEaBVWBMQkrSjsl",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid036kiZCngRH2HVNDag9YwmgYAdTZoWoV3LqmMN8smYR3YwiQQS3PNJSrXp3FXqrcafl",
    customer_name: "Danika Empleo",
    review_text: "Easy and safe transaction through their online website!",
    link: "https://www.facebook.com/danika.empleo/posts/pfbid036kiZCngRH2HVNDag9YwmgYAdTZoWoV3LqmMN8smYR3YwiQQS3PNJSrXp3FXqrcafl",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02YoK5dK5DvDSZvbr7EpwYsuB2rFJxbSGniiLX1W8qoZdhFunMcTJ4ZZpEmNyyC1AYl",
    customer_name: "John Michael De Leon",
    review_text:
      "The service is nice, they are accommodating, responsive, store is cozy and clean, they also guided me properly the time na bumili ako ng laptop. Also, thank you to Sir Ramil for the service both online and in-store! ^^",
    link: "https://www.facebook.com/j.miyayo11/posts/pfbid02YoK5dK5DvDSZvbr7EpwYsuB2rFJxbSGniiLX1W8qoZdhFunMcTJ4ZZpEmNyyC1AYl",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid023HmiK6sGWb1kRURGGvCA6WsZXYBsz5RFn48PMyJgtE8g6ePmgFNGhkfBTWnNvhHql",
    customer_name: "Jâi",
    review_text:
      "Sobrang sulit po ng brief \n. Very responsive sila sa inquiries and accommodating ang mga staffs. Thank you Sir Sonny !",
    link: "https://www.facebook.com/jaiiiisantiago/posts/pfbid023HmiK6sGWb1kRURGGvCA6WsZXYBsz5RFn48PMyJgtE8g6ePmgFNGhkfBTWnNvhHql",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0yNPwK9WVeJhnZCGfjaKYYzLKdiRxgqiLmFFPG971GtVrA8tj773LQRf2ogb83S6Cl",
    customer_name: "Carlos Rodriguez",
    review_text:
      "Sobrang recommended ang PC Worth sa lahat ng mga computer and laptop needs ninyo \n mas okay ang prices at may same day delivery pa para sa personal/office use hehe more power kay Sir Ramil and team! sa uulitin",
    link: "https://www.facebook.com/carlosrodriii/posts/pfbid0yNPwK9WVeJhnZCGfjaKYYzLKdiRxgqiLmFFPG971GtVrA8tj773LQRf2ogb83S6Cl",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0WL2fRQ7yVSwsDkShzTVy5wMw7pxj96HBGGJaTEsBAPC8CdB9NsUbqyLoWPiy8Tutl",
    customer_name: "Dylan McBan",
    review_text:
      "Maraming salamat sa napagandang serbisyo! Recommended for newbie pc builder. Solid products mura presyo compare sa iba at 10000/10 ang after sales service. Will recommend sa friends ko.",
    link: "https://www.facebook.com/kerby.anderson.98/posts/pfbid0WL2fRQ7yVSwsDkShzTVy5wMw7pxj96HBGGJaTEsBAPC8CdB9NsUbqyLoWPiy8Tutl",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0AU5h2tpJtKVinM7dLXKomzjaVrKmjyqz5EV9jkXoNRpC4mDxFS281W7fLTe8otKul",
    customer_name: "Chris Alviz",
    review_text:
      "I recently had the pleasure of working with PC Worth to assemble my CPU, and I couldn’t be happier with the experience. From start to finish, their team demonstrated efficiency, patience, and expertise. They made the entire assembly process seamless. They handled each component with care, ensuring proper installation and cable management. I appreciated their attention to detail, as it resulted in a clean and organized build.",
    link: "https://www.facebook.com/christopher.alviz.75/posts/pfbid0AU5h2tpJtKVinM7dLXKomzjaVrKmjyqz5EV9jkXoNRpC4mDxFS281W7fLTe8otKul",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02NjUX9rG4raudmichY2p8EsjUxbRD55qfD5HfvpUUVs4eBpmvq7ziTyyCToC5vekhl",
    customer_name: "Yunieboy",
    review_text:
      "Sa wakas may bagong briefs nako! Sulit bumili ng briefs dito sobrang panalo ng freebies!",
    link: "https://www.facebook.com/yunieboy.yunieboy/posts/pfbid02NjUX9rG4raudmichY2p8EsjUxbRD55qfD5HfvpUUVs4eBpmvq7ziTyyCToC5vekhl",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02LaKYTbLLwhydiQ5smLzdWKVybdE5unEVrKrSjGV9t4rF9SdvKx4TzRQw8bpLzcdSl",
    customer_name: "Emmanuel Casiano",
    review_text:
      "Really great customer service and they'd even talk to you for like days and even suggest PC parts that they know will be better for the budget and performance.",
    link: "https://www.facebook.com/em.casiano03/posts/pfbid02LaKYTbLLwhydiQ5smLzdWKVybdE5unEVrKrSjGV9t4rF9SdvKx4TzRQw8bpLzcdSl",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0ZWvTwkXX4UG19VyfM3C2vnrzHsBpjBfjrPcsSZx1U4WFgfMHY4attZTm9vPBYXXrl",
    customer_name: "Ken Coquilla Paraiso",
    review_text:
      "sobrang solid pc worth! nag ot pa staff para ma accommodate mga customer. Maraming salamat sa staff, sir ramil, at sa pc worth. Solid kayo! Highly recommend!",
    link: "https://www.facebook.com/permalink.php?story_fbid=pfbid0ZWvTwkXX4UG19VyfM3C2vnrzHsBpjBfjrPcsSZx1U4WFgfMHY4attZTm9vPBYXXrl&id=100008882640918&comment_id=842125617424472&__cft__[0]=AZULiZYQY2J3j4Ib4RCLXIyRnXazD4XHtaUQ4BlSsGrgMmIS_4aoGOUIg_e6K4uyqwxInC_CfhfhPWryWuJZnKROcdhqrMMRWMhDrSNl930v0w3hUbmLQ3okrFI8e2_2keTLBZMZnk6xA8Sby0dYFEuA&__tn__=R]-R",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0tvYvWeabmHdfUYNJdYJCFZzif7DHAXXnAnEfVwL1c39mt6obXFxfTBDEjjehDrF3l",
    customer_name: "Lance Cruz",
    review_text:
      "Sobrang sulit ng binebentang brief sa PC WORTH may libreng pc pag bumili ka",
    link: "https://www.facebook.com/LanceCruz27/posts/pfbid0tvYvWeabmHdfUYNJdYJCFZzif7DHAXXnAnEfVwL1c39mt6obXFxfTBDEjjehDrF3l",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0UuU3TRBTUpUnZ4MREdZSda85wG1Zug7iqwDEYvhyEHemX7Auh8CNC1mydZKs9kaQl",
    customer_name: "Gwynne Amisola",
    review_text:
      "Thank you PC Worth, the staff was friendly and knowledgeable while suggesting helpful upgrades on my pc. Highly recommended and also thanks to Ramil! for assisting me.",
    link: "https://www.facebook.com/gwynneamisola05/posts/pfbid0UuU3TRBTUpUnZ4MREdZSda85wG1Zug7iqwDEYvhyEHemX7Auh8CNC1mydZKs9kaQl",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02hF1a85Jej7K8AwpZsRaASg1iLsBxrP8g1nNZdJtowJds2sAZ8pRsyA8HugZXB7Wnl",
    customer_name: "Aljo Cabacoy",
    review_text:
      "Good service and prices, they are really willing to help and will answer all your inquiries. Thank you to sir Ramil for assisting me sa questions ko sa chat and kay sir Kenneth naman sa store",
    link: "https://www.facebook.com/Hassanxaxa/posts/pfbid02hF1a85Jej7K8AwpZsRaASg1iLsBxrP8g1nNZdJtowJds2sAZ8pRsyA8HugZXB7Wnl",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02uqjb2tbacJVLxsSGJiv2jwsaUPxAD46qVWibxUWdNMGjShpUVyFFXfuhUhYo2hiQl",
    customer_name: "Jed Zuriel Belleza Calma",
    review_text:
      "Good prices, place and staff especially kay sir Ramil na nag assist sakin. 5 out of 5 overall!",
    link: "https://www.facebook.com/jeedding/posts/pfbid02uqjb2tbacJVLxsSGJiv2jwsaUPxAD46qVWibxUWdNMGjShpUVyFFXfuhUhYo2hiQl",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02qwfr4bwCiwhTzpt5461t1jZiTxZ8ea9KnyWvJwg9R6zaUZmokAgX1W4rLSNetUaBl",
    customer_name: "Vicente Jr R Sayson",
    review_text:
      "Good build at cheap price at napaka daling kausap ng cs. plus may PC kana may Brief kapa. San kapa ? haha",
    link: "https://www.facebook.com/say.vic14/posts/pfbid02qwfr4bwCiwhTzpt5461t1jZiTxZ8ea9KnyWvJwg9R6zaUZmokAgX1W4rLSNetUaBl",
    created_time: "11 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid025YVjXhHVmVJbJwXrg8ewA2VdapusEeY5KXUmA3duzeyPVJNEVcsJ7MPiwQemsZjvl",
    customer_name: "Damon Di Es",
    review_text:
      "Fast build and one of the best price range ng pc components. Props sa pag-assist sakin ni Sir Ramil mabilis ang reply at may mga suggestions pa during pagbubuild ko.",
    link: "https://www.facebook.com/permalink.php?story_fbid=pfbid025YVjXhHVmVJbJwXrg8ewA2VdapusEeY5KXUmA3duzeyPVJNEVcsJ7MPiwQemsZjvl&id=100079187988932&comment_id=1630737501033157&__cft__[0]=AZVwH8mrWZHrfLWmoolDcK9eEmc4u50kbqVQn5QFgriLUX0UXQ3BojlvmfDargPHZQzFBkmfgbAuJEiq1vLLSqypEvp3AYnBnrTGFZKoPF56Dyfs9GsP6TSH5hfOQKzhErWfkSSkNoO7jibkAaWfcf-4QSbvVVvc-HEvvou3wOR8QzPxOOOj_W288wimHFNLpRE&__tn__=R]-R",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0pCYRcvJxeQoioQCwqMc8ddxXUmyyuQUUy4KSfsvDcTUN5apWbVRf911JVPoBkKtXl",
    customer_name: "Ryan Oliveros",
    review_text:
      "Shoutout to Boss PJ and to whole PC Worth team in Earnshaw St, Sampaloc! Thanks sa smooth and pogi na build!",
    link: "https://www.facebook.com/ryan.oliveros.92/posts/pfbid0pCYRcvJxeQoioQCwqMc8ddxXUmyyuQUUy4KSfsvDcTUN5apWbVRf911JVPoBkKtXl",
    created_time: "9 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02ST4Cov7BTVuuu2fbMwhDxs9RUmbodUdvwJDa6afUJMRN997RXk5iTwLo3X4m4h7sl",
    customer_name: "Kevin Jico Trinidad Tolentino",
    review_text:
      "I recently purchased a system unit from PC Worth, and I couldn't be happier with the service I received, especially from Ramil. From the very beginning, the transaction was incredibly fast and smooth. Ramil was always prompt in replying to all my questions, providing detailed and helpful answers that made the entire process stress-free.",
    link: "https://www.facebook.com/kevinjico/posts/pfbid02ST4Cov7BTVuuu2fbMwhDxs9RUmbodUdvwJDa6afUJMRN997RXk5iTwLo3X4m4h7sl",
    created_time: "11 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02jL1rGdYwMq7ZBrNZjr482wPENrR3ouwP4s9Z9NTxYPPvt1dgB2F3yAEtKENdCU5Fl",
    customer_name: "Tristan De Leon",
    review_text:
      "PC Worth is one great shop for your pc needs, very affordable price and sobrang friendly and helpful ng staff  specially kay Sir Kamid who help me build my PC and to Sir Marshall who assist me and provide smooth transaction.",
    link: "https://www.facebook.com/tristan.deleon.5/posts/pfbid02jL1rGdYwMq7ZBrNZjr482wPENrR3ouwP4s9Z9NTxYPPvt1dgB2F3yAEtKENdCU5Fl",
    created_time: "11 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid0H2tzsbaS7TXf5njgrF8tH5tmVt5qWhesJGhXvjprN1pj6dV6wZmP6xFcBVxvynTYl",
    customer_name: "Christian Buan",
    review_text:
      "Very responsive and has good customer service. Special thanks to sir Marshall sa pag assist.",
    link: "https://www.facebook.com/zanereee/posts/pfbid0H2tzsbaS7TXf5njgrF8tH5tmVt5qWhesJGhXvjprN1pj6dV6wZmP6xFcBVxvynTYl",
    created_time: "11 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02xfstpjbwnfbYxpYtXhqNP7o9QQPV9bhbnxZgjttQCUwCdoLwRvyTNvaQVMxw24Lrl",
    customer_name: "Marcial Sto Domingo",
    review_text:
      "Highly recommended! Excellent customer service and very smooth ang transaction. Kahit gabi na ako nag inquire, na-assist agad ako and nakuha kinabukasan ang pinabuild ko na pc \n Thanks Sir Marshall for accommodating! Will definitely purchase again next time for my next builds!",
    link: "https://www.facebook.com/marcialstodomingo/posts/pfbid02xfstpjbwnfbYxpYtXhqNP7o9QQPV9bhbnxZgjttQCUwCdoLwRvyTNvaQVMxw24Lrl",
    created_time: "11 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid07F7YEh53gPX4qKjW1Gk3iTziqDvQDMEd9PgebQvrXrisFFSHJ72AoYG37KDVUxy5l",
    customer_name: "Denwel",
    review_text:
      "Thank you PC Worth for the smooth transaction and service! Friendly and mabilis yung service nila and lalo na yung customer service, Thank you, Sir Sonny! Alam din nila kung sino uunahin sa pag build sa shop and well organized. Highly recommended, mura na, legit pa!",
    link: "https://www.facebook.com/SenpaiDenwel/posts/pfbid07F7YEh53gPX4qKjW1Gk3iTziqDvQDMEd9PgebQvrXrisFFSHJ72AoYG37KDVUxy5l",
    created_time: "11 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02QTqcWsDrBk4NzzPm9z8HYphvwierhMtSnvj3YYgqWGzKxzstN9wYsRdSSogKZqzWl",
    customer_name: "Jeff Bilaos",
    review_text:
      "Very accomodating lahat ng staff nila, good quality service, and also na demonstrate nila lahat ng product nila ng ma ayos. Thank you sir Ramil sa walang humpay na pag assist sa amin.",
    link: "https://www.facebook.com/harrisbilaos/posts/pfbid02QTqcWsDrBk4NzzPm9z8HYphvwierhMtSnvj3YYgqWGzKxzstN9wYsRdSSogKZqzWl",
    created_time: "11 weeks ago",
  },
  {
    type: "fb",
    review_id:
      "pfbid02vWcakGvSwwUhrZyRULuGniAWvSR7MetK3BjV51NxSe6eM1x4EcmzeDmuYECuEF6Bl",
    customer_name: "Glor Flores",
    review_text:
      "mabilis mag reply sa customer kahit nagtatanong ka lang mahaba pasensya. ibibigay yung gusto mong budget na high quality na gusto mo. 5 star deserve",
    link: "https://www.facebook.com/glorbarranco/posts/pfbid02vWcakGvSwwUhrZyRULuGniAWvSR7MetK3BjV51NxSe6eM1x4EcmzeDmuYECuEF6Bl",
    created_time: "11 weeks ago",
  },
];
