import React from "react";
import { Box } from "@mui/material";
import { Place, LocalPhone, PhoneAndroid } from "@mui/icons-material";
import { FlexBox } from "components/flex-box";
import { Span } from "components/Typography";

interface BranchInfoProps {
  branchName?: string;
  location?: string;
  href?: string;
  telNo?: string;
  cellNo?: string;
  cellNo2?: string;
}

const BranchInfo: React.FC<BranchInfoProps> = ({
  branchName,
  location,
  href,
  telNo,
  cellNo,
  cellNo2,
}) => {
  return (
    <div>
      <Box color="#fff">{branchName}:</Box>
      <FlexBox gap={1}>
        <Place fontSize="small" sx={{ fontSize: 17, mt: "3px" }} />
        <Span color="white">
          <a href={href} target="_blank" rel="noopener noreferrer">
            {location}
          </a>
        </Span>
      </FlexBox>

      {telNo && (
        <FlexBox gap={1}>
          <LocalPhone fontSize="small" sx={{ fontSize: 17, mt: "3px" }} />
          <Span color="white">{telNo}</Span>
        </FlexBox>
      )}

      {cellNo2 ? (
        <FlexBox mb={2} gap={1}>
          <PhoneAndroid fontSize="small" sx={{ fontSize: 17, mt: "3px" }} />
          <Span color="white">
            {cellNo} / {cellNo2}
          </Span>
        </FlexBox>
      ) : (
        <FlexBox mb={2} gap={1}>
          <PhoneAndroid fontSize="small" sx={{ fontSize: 17, mt: "3px" }} />
          <Span color="white">{cellNo}</Span>
        </FlexBox>
      )}
    </div>
  );
};

export default BranchInfo;
