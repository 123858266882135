import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

const useResolution = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const isMobile = useMemo(() => {
    return width <= 900 || height <= 900;
  }, [height, width]);

  const isSmallMobile = useMemo(() => {
    return width <= 600 || height <= 900;
  }, [height, width]);

  const isLandscape = useMemo(() => {
    return width > height;
  }, [height, width]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const windowListener = useCallback(
    debounce(() => {
      if (window) {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      }
    }, 250),
    []
  );

  const isXs = useMemo(() => width < breakpoints.sm, [width]);
  const isSm = useMemo(
    () => width >= breakpoints.sm && width < breakpoints.md,
    [width]
  );
  const isMd = useMemo(
    () => width >= breakpoints.md && width < breakpoints.lg,
    [width]
  );
  const isLg = useMemo(
    () => width >= breakpoints.lg && width < breakpoints.xl,
    [width]
  );
  const isXl = useMemo(() => width >= breakpoints.xl, [width]);

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      window.addEventListener("resize", windowListener);
    }
    return () => {
      windowListener.cancel();
      window && window.removeEventListener("resize", windowListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    width,
    height,
    isMobile,
    isSmallMobile,
    isLandscape,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
  };
};

export default useResolution;
