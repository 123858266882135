import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import { ProductDetailsApi } from "./api";
import {
  GetProductDetailsPayload,
  GetProductDetailsResponse,
  GetProductHasStocksPayload,
  GetProductHasStocksResponse,
  GetProductImagesPayload,
  GetProductImagesResponse,
} from "./types";
import { GetAvailabilityBranchesOfProductResponse } from "modules/products/types";

export const getProductDetailThunk = createAsyncThunk<
  CommonAxiosResponse<GetProductDetailsResponse>,
  GetProductDetailsPayload
>("ProductDetail/getProductDetailThunk", async (data) => {
  const res = await ProductDetailsApi.getProductDetails(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getProductImagesThunk = createAsyncThunk<
  CommonAxiosResponse<GetProductImagesResponse>,
  GetProductImagesPayload
>("ProductDetail/getProductDetailThunk", async (data) => {
  const res = await ProductDetailsApi.getProductImages(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getAvailabilityBranchOfProductsThunk = createAsyncThunk<
  CommonAxiosResponse<GetAvailabilityBranchesOfProductResponse>,
  string
>("Products/getAvailabilityBranchOfProductsThunk", async (uuid) => {
  const res = await ProductDetailsApi.getAvailabilityBranchesOfProductApi(uuid);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getProductHasStocksThunk = createAsyncThunk<
  CommonAxiosResponse<GetProductHasStocksResponse>,
  GetProductHasStocksPayload
>("Products/getProductHasStocksThunk", async (data) => {
  const res = await ProductDetailsApi.getProductHasStocks(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
