import { Badge, Box, Drawer, styled } from "@mui/material";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import clsx from "clsx";
import Image from "components/BazarImage";
import { FlexBox } from "components/flex-box";
import ShoppingBagOutlined from "components/icons/ShoppingBagOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MiniCart from "components/mini-cart/MiniCart";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useEffect, useMemo, useState } from "react";
import SearchBox, { SearchOutlinedIcon } from "../search-box/SearchBox";
import HistoryIcon from "@mui/icons-material/History";
import { useCart } from "hooks/use-cart";
import { useDialogueShow } from "hooks/useDialogue";
import { useLoginStatus } from "hooks/useLoginStatus";
import { usePcwUserCart } from "hooks/use-pcw-cart";
import { navigationRoutes } from "common/constants/navigation-routes";
import { IconShoppingCart } from "components/icons/IconShoppingCart";
import { makeStyles } from "@mui/styles";
import { useOpacity } from "hooks/useOpacity";
import { IconFBMessenger } from "components/icons/IconFBMessenger";
import { useLinkHandler } from "hooks";
import { MessengerFB } from "components/icons/MessengerFB";
import useWindowSizeV2 from "hooks/use-window-size-v2";

// styled component
export const HeaderWrapper = styled(Box)(({ theme }) => ({
  zIndex: 3,
  position: "relative",
  paddingTop: 12,
  paddingBottom: 8,
  transition: "height 250ms ease-in-out, opacity 0.4s ease",
  transform: "translateY(0)", //default position
  background: theme.palette.background.paper,
}));

// ==============================================================
type HeaderProps = {
  isFixed?: boolean;
  hasSearch?: boolean;
  className?: string;
  isSearchFocus?: boolean;
  onClickNavigateToCartPage?: boolean;
  handleSearchClick?: () => void;
};
// ==============================================================

const useStyles = makeStyles({
  mobileHeader: {
    paddingTop: 8,
    paddingBottom: 0,
  },
});

const Header: FC<HeaderProps> = ({
  onClickNavigateToCartPage,
  className,
  hasSearch = true,
  isSearchFocus,
  handleSearchClick,
}) => {
  const router = useRouter();
  const classes = useStyles();
  const { pathname } = useRouter();

  const { cartLength, orderHistory } = useCart();
  const { isUserLogin } = useLoginStatus();
  const { pcwUserCartLength, getUserCartLength } = usePcwUserCart();
  const { showLoginFormOpen, showSignUpFormClose } = useDialogueShow();
  const {
    opacity,
    setOpacity,
    lastScrollY,
    isScrolledUp,
    scrolling,
    setScrolling,
    handleScroll,
  } = useOpacity();
  const { openFacebookMessenger } = useLinkHandler();
  const { isMobile, isTablet, isDesktop } = useWindowSizeV2();

  const mobileHeader = isMobile ? classes.mobileHeader : "";
  const isLogin = isUserLogin;

  const [sidenavOpen, setSidenavOpen] = useState(false);

  const isCheckoutPage = useMemo(() => {
    return pathname === navigationRoutes.checkout;
  }, [pathname]);

  const newOrderHistory = orderHistory?.filter((item) => {
    return !item?.user_id;
  });

  const toggleSidenav = () => {
    if (isLogin) {
      router.push("/user-cart");
    } else {
      if (isMobile || onClickNavigateToCartPage) {
        router.push("/cart");
        return;
      }
      // Minicart is only used here
      setSidenavOpen(!sidenavOpen);
    }
  };

  const toggleLogin = () => {
    //if user is currently logged in, the header redirect the user to profile else show login form
    if (isLogin) {
      router.push(navigationRoutes.pcwProfile);
    } else {
      showLoginFormOpen();
    }
  };

  useEffect(() => {
    isLogin && !pcwUserCartLength ? getUserCartLength() : null;
  }, [getUserCartLength, isLogin, pcwUserCartLength]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    const timer = setTimeout(() => {
      if (scrolling) {
        setScrolling(false);
        setOpacity(1);
      }
    }, 500);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll, lastScrollY, scrolling]);

  return (
    <HeaderWrapper
      className={`${clsx(className)} ${mobileHeader}`}
      sx={{
        opacity: 1,
        transition: "transform 0.5s ease",
        transform: isMobile
          ? lastScrollY === 0 || isScrolledUp
            ? "translateY(0)"
            : "translateY(-100%)"
          : "translateY(0)",
      }}
    >
      {/* Mobile view */}
      {isMobile ? (
        <>
          <FlexBox
            paddingX={3}
            mb={hasSearch ? 1 : 0}
            alignItems="center"
            justifyContent="space-between"
            sx={{
              pointerEvents: scrolling ? "none" : "auto",
            }}
          >
            <Link href={navigationRoutes.homepage}>
              <a>
                <Image
                  height={50}
                  src="/assets/images/logo_pc_worth.png"
                  alt="logo"
                />
              </a>
            </Link>
            {!isCheckoutPage && (
              <div>
                {newOrderHistory?.length > 0 && !isLogin ? (
                  <Badge badgeContent={newOrderHistory?.length} color="primary">
                    <Box
                      p={1.25}
                      component={IconButton}
                      onClick={() => router.push("/cart")}
                    >
                      <HistoryIcon />
                    </Box>
                  </Badge>
                ) : null}
                {isMobile ? (
                  <Badge color="primary">
                    <Box
                      p={1.25}
                      component={IconButton}
                      onClick={handleSearchClick}
                    >
                      <SearchOutlinedIcon sx={{ margin: 0 }} />
                    </Box>
                  </Badge>
                ) : null}
                <Badge color="primary">
                  <Box p={1.25} component={IconButton} onClick={toggleSidenav}>
                    <IconShoppingCart />
                  </Box>
                </Badge>
                <Badge color="primary">
                  <Box
                    p={1.25}
                    component={IconButton}
                    onClick={openFacebookMessenger}
                  >
                    <MessengerFB
                      sx={{
                        fontSize: 22,
                        color: "#7d879c",
                        opacity: 1,
                      }}
                    />
                  </Box>
                </Badge>
              </div>
            )}
          </FlexBox>
          <Box sx={{ width: "100%" }}>
            {hasSearch && isMobile ? (
              <Box sx={{ mx: 1, my: 1 }}>
                <SearchBox isFocus={isSearchFocus} />
              </Box>
            ) : null}
          </Box>
        </>
      ) : (
        <Container
          sx={{
            gap: 2,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FlexBox
            mr={2}
            minWidth="170px"
            alignItems="center"
            sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
          >
            <Link href={navigationRoutes.homepage}>
              <a>
                <Image
                  height={40}
                  src="/assets/images/logo_pc_worth_v2.png"
                  alt="logo"
                />
              </a>
            </Link>
          </FlexBox>
          {!isCheckoutPage && (
            <>
              <FlexBox justifyContent="center" flex="1 1 0">
                {hasSearch || isTablet || isDesktop ? (
                  <SearchBox isFocus={isSearchFocus} />
                ) : null}
              </FlexBox>
              <FlexBox
                alignItems="center"
                sx={{
                  display: { xs: "none", sm: "flex", md: "flex" },
                }}
              >
                <Box
                  ml={2.5}
                  p={1.25}
                  bgcolor="grey.200"
                  component={IconButton}
                  onClick={openFacebookMessenger}
                >
                  <IconFBMessenger />
                </Box>
                {newOrderHistory?.length > 0 && !isLogin ? (
                  <Badge badgeContent={newOrderHistory?.length} color="primary">
                    <Box
                      ml={2.5}
                      p={1.25}
                      bgcolor="grey.200"
                      component={IconButton}
                      onClick={() => router.push("/cart")}
                    >
                      <HistoryIcon />
                    </Box>
                  </Badge>
                ) : null}

                <Badge color="primary">
                  <Box
                    ml={2.5}
                    p={1.25}
                    bgcolor="grey.200"
                    component={IconButton}
                    onClick={toggleLogin}
                  >
                    <AccountCircleIcon />
                  </Box>
                </Badge>

                <Badge
                  badgeContent={isLogin ? pcwUserCartLength : cartLength}
                  color="primary"
                >
                  <Box
                    ml={2.5}
                    p={1.25}
                    bgcolor="grey.200"
                    component={IconButton}
                    onClick={toggleSidenav}
                  >
                    <ShoppingBagOutlined />
                  </Box>
                </Badge>
              </FlexBox>
            </>
          )}
          <Drawer open={sidenavOpen} anchor="right" onClose={toggleSidenav}>
            <MiniCart />
          </Drawer>
        </Container>
      )}
    </HeaderWrapper>
  );
};

export default Header;
