import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, RootState, thunkResponseHandler } from "common";
import { ProductsApi } from "./api";
import {
  ProductFiltersPayload,
  ProductFiltersResponse,
  BestSellingProductResponse,
  AlternativeProductPayload,
  AlternativeProductResponse,
  ProductsPayload,
  ProductsResponse,
} from "./types";

export const getHomePageProductsThunk = createAsyncThunk<
  CommonAxiosResponse<ProductsResponse>,
  undefined,
  { state: RootState }
>("Products/getHomePageProductsThunk", async (data, api) => {
  const request = api.getState().Products.getHomeProductsRequest;
  const res = await ProductsApi.getProducts(request);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getHomePageNextPageProductsThunk = createAsyncThunk<
  CommonAxiosResponse<ProductsResponse>,
  undefined,
  { state: RootState }
>("Products/getHomePageNextPageProductsThunk", async (_, api) => {
  const request = api.getState().Products.getHomeProductsRequest;
  const res = await ProductsApi.getProducts(request);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

// ilagay na sa thunk na to yung mga limit, and qmanufacturer
// Get products for a specific brand, ASUS in this case
export const getAsusBrandProductsThunk = createAsyncThunk<
  CommonAxiosResponse<ProductsResponse>,
  ProductsPayload
>("Products/getAsusBrandProductsThunk", async (data) => {
  const res = await ProductsApi.getProducts({
    limit: 48,
    page: 1,
    qmanufacturer: "Asus",
    sort_direction: "asc",
    keyword: "",
  });
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

// ilagay na sa thunk na to yung mga limit, and qmanufacturer
// Get products for a specific brand, Gigabyte in this case
export const getGigabyteBrandProductsThunk = createAsyncThunk<
  CommonAxiosResponse<ProductsResponse>,
  ProductsPayload
>("Products/getGigabyteBrandProductsThunk", async (data) => {
  const res = await ProductsApi.getProducts({
    limit: 48,
    page: 1,
    qmanufacturer: "Gigabyte",
    sort_direction: "asc",
    keyword: "",
  });
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

// ilagay na sa thunk na to yung mga limit, and qmanufacturer
// Get products for a specific brand, MSI in this case
export const getMsiBrandProductsThunk = createAsyncThunk<
  CommonAxiosResponse<ProductsResponse>,
  ProductsPayload
>("Products/getMsiBrandProductsThunk", async (data) => {
  const res = await ProductsApi.getProducts({
    limit: 48,
    page: 1,
    qmanufacturer: "MSI",
    sort_direction: "asc",
    keyword: "",
  });
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

// ilagay na sa thunk na to yung mga limit, and qmanufacturer
// Get products for a specific brand, MSI in this case
export const getAsrockBrandProductsThunk = createAsyncThunk<
  CommonAxiosResponse<ProductsResponse>,
  ProductsPayload
>("Products/getAsrockBrandProductsThunk", async (data) => {
  const res = await ProductsApi.getProducts({
    limit: 48,
    page: 1,
    qmanufacturer: "ASRock",
    sort_direction: "asc",
    keyword: "",
  });
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getSearchBarProductsThunk = createAsyncThunk<
  CommonAxiosResponse<ProductsResponse>,
  string
>("Products/getSearchBarProductsThunk", async (data) => {
  const res = await ProductsApi.getProducts({
    keyword: data,
    limit: 10,
  });
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getLatestProductsThunk = createAsyncThunk<
  CommonAxiosResponse<ProductsResponse>,
  ProductsPayload,
  { state: RootState }
>("Products/getLatestProductsThunk", async (data) => {
  const res = await ProductsApi.getProducts({
    limit: 12,
    ...data,
    recently_added: true,
  });
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getBestSellingProductsThunk = createAsyncThunk<
  CommonAxiosResponse<BestSellingProductResponse>,
  // ProductsPayload,
  undefined,
  { state: RootState }
>("Products/getBestSellingProductsThunk", async (data) => {
  const res = await ProductsApi.getBestSellingProductsApi();
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getSearchPageProductsThunk = createAsyncThunk<
  CommonAxiosResponse<ProductsResponse>,
  ProductsPayload,
  { state: RootState }
>("Products/getSearchPageProductsThunk", async (data) => {
  const res = await ProductsApi.getProducts(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getAllProductsPageThunk = createAsyncThunk<
  CommonAxiosResponse<ProductsResponse>,
  ProductsPayload,
  { state: RootState }
>("Products/getAllProductsPageThunk", async (request) => {
  const res = await ProductsApi.getProducts(request);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

// for deletion? no longer used cause product filters now come from endpoint
// ecomm/products/available/get response under "facet" props
// export const getProductsFilterThunk = createAsyncThunk<
//   CommonAxiosResponse<ProductFiltersResponse>,
//   ProductFiltersPayload,
//   { state: RootState }
// >("Products/getProductsFilterThunk", async (request) => {
//   const res = await ProductsApi.getProductsFilter(request);
//   const toThunk = thunkResponseHandler(res);
//   return toThunk;
// });

export const getAlternativeProductsThunk = createAsyncThunk<
  CommonAxiosResponse<AlternativeProductResponse>,
  AlternativeProductPayload,
  { state: RootState }
>("Products/getAlternativeProductsThunk", async (request) => {
  const res = await ProductsApi.getAlternativeProducts(request);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
