/* eslint-disable @next/next/no-img-element */
import { Box } from "@mui/material";
import { PromotionalBadge } from "modules/promotional/types";
import { FC, memo, useCallback, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

type Props = {
  promotionalBadge?: PromotionalBadge[];
  slidesPerViews?: number;
  onClick: (id?: string) => void;
};

const Component: FC<Props> = ({ promotionalBadge = [], onClick }) => {
  const [isLastSlideVisible, setIsLastSlideVisible] = useState(false);

  const stopPropagation = useCallback(
    (event: MouseEvent | TouchEvent | PointerEvent) => {
      event.stopPropagation();
    },
    []
  );

  const handleSlideChange = (swiper: any) => {
    // Calculate if the last slide is fully or partially visible
    const isLastVisible =
      swiper.isEnd ||
      swiper.activeIndex >= promotionalBadge.length - swiper.slidesPerView;
    setIsLastSlideVisible(isLastVisible);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "35px",
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          right: -10,
          bottom: 0,
          width: "12%",
          background: isLastSlideVisible
            ? "none"
            : "linear-gradient(to right, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 1) 100%)",
          filter: isLastSlideVisible ? "none" : "blur(5px)",
          zIndex: 1,
        },
      }}
    >
      <Swiper
        spaceBetween={1}
        slidesPerView={"auto"}
        grabCursor={true}
        centeredSlides={false}
        loop={false}
        style={{ width: "100%", background: "none" }}
        onSlideChange={handleSlideChange}
        onTouchStart={(_, event) => stopPropagation(event)}
        onTouchMove={(_, event) => stopPropagation(event)}
        onTouchEnd={(_, event) => stopPropagation(event)}
        onClick={(_, event) => stopPropagation(event)}
      >
        {promotionalBadge.map((i) => (
          <SwiperSlide
            key={i.slug}
            style={{
              padding: 1,
              background: "none",
              width: "auto",
            }}
          >
            <Box
              sx={{
                height: "30px",
              }}
            >
              <img
                src={i?.badge_url || "/assets/images/kachi/kachi-crying.png"}
                alt={i.slug}
                height={30}
                width={"auto"}
                onClick={() => onClick(i?.id)}
              />
            </Box>
          </SwiperSlide>
        ))}

        {/* Dummy slide: for triggering last slide listener */}
        <SwiperSlide
          style={{
            padding: 1,
            background: "none",
            width: "5px",
          }}
        ></SwiperSlide>
      </Swiper>
    </Box>
  );
};

export const PromoBadges = memo(Component);
