import { Box, Grid } from "@mui/material";
import { _ } from "common";
import CategorySectionHeader from "components/CategorySectionHeader";
import ProductCard1 from "components/product-cards/ProductCard1";
import { ProductsSkeleton } from "components/skeletons";
import { FC, useEffect } from "react";
import modules from "modules";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useAppSelector } from "hooks/use-app-selector";

//swiper package and modules
import { Swiper, SwiperSlide } from "swiper/react";
//swiper style needed*
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css";
import SwiperCarousel from "components/swiper/SwiperCarousel";

const { actions: productsActions, selectors: productsSelectors } =
  modules.products;

const LatestProductsSection: FC = () => {
  const dispatch = useAppDispatch();

  const breakpoints = {
    0: { slidesPerView: 2.25 },
    600: { slidesPerView: 3.25 },
    900: { slidesPerView: 4.25 },
    1200: { slidesPerView: 5.25 },
    1536: { slidesPerView: 6.25 },
  };

  const products = useAppSelector(productsSelectors.selectLatestProductsItems);
  const productsLoading = useAppSelector(
    productsSelectors.selectLatestProductsLoading
  );

  const getLatestProducts = () => {
    dispatch(productsActions.getLatestProductsThunk({}));
  };

  useEffect(() => {
    getLatestProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return products?.[0] ? (
    <Box flex="1 1 auto" minWidth="0px" mt={4}>
      <CategorySectionHeader
        title="Latest Products"
        seeMoreLinkTxt="See All"
        seeMoreLink="/latest-products"
      />
      <SwiperCarousel
        id="products"
        data={products}
        renderItem={(item) => (
          <ProductCard1
            hoverEffect
            productData={item}
            isNewProduct={item.is_latest_product}
          />
        )}
        isLoading={productsLoading}
        breakpoints={breakpoints}
      />
    </Box>
  ) : null;
};

export default LatestProductsSection;
