import {
  Button,
  Container,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import TurnLeftIcon from "@mui/icons-material/TurnLeft";
import { FC } from "react";
import { colors, openLinkWithApps } from "common";
import {
  accountMenuOptionsFlag,
  blogsFlag,
  promotionalsFlag,
} from "common/constants/feature-toggles";
import { NavBarItemList } from "common/types/mobile-navbar";
import { IconMenuHome } from "components/icons/IconMenuHome";
import { MainMenuDrawerItem } from "./MobileMenuDrawerItem";
import { MenuDrawerTitle } from "./MainMenuDrawerSectionTitle";
import { IconMenuSearch } from "components/icons/IconMenuSearch";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import { IconMenuMonitor } from "components/icons/IconMenuMonitor";
import { navigationRoutes } from "common/constants/navigation-routes";
import { IconMenuPictureGallery } from "components/icons/IconMenuPictureGallery";
import { IconMenuRobot } from "components/icons/IconMenuRobot";
import { IconMenuBox } from "components/icons/IconMenuBox";
import { IconMenuPricelist } from "components/icons/IconMenuPricelist";
import { IconMenuSignIn } from "components/icons/IconMenuSignIn";
import { IconMenuAccount } from "components/icons/IconMenuAccount";
import { IconMenuTerms } from "components/icons/IconMenuTerms";
import { IconMenuOrder } from "components/icons/IconMenuOrder";
import { IconMenuQuestion } from "components/icons/IconMenuQuestion";
import { IconMenuPolicy } from "components/icons/IconMenuPolicy";
import { IconMenuFB } from "components/icons/IconMenuFB";
import { IconMenuTiktok } from "components/icons/IconMenuTiktok";
import { IconMenuInstagram } from "components/icons/IconMenuInstagram";
import { useCompanyInfo } from "hooks/useCompanyInfo";
import { useLoginStatus } from "hooks/useLoginStatus";
import { IconMenuShoppingBag } from "components/icons/IconMenuShoppingBag";
import { IconMenuMyPurchased } from "components/icons/IconMenuMyPurchased";
import { IconMenuLocation } from "components/icons/IconMenuLocation";
import { IconMenuProfileInfo } from "components/icons/IconMenuProfileInfo";
import { IconMenuSecurity } from "components/icons/IconMenuSecurity";
import { useDialogueShow } from "hooks/useDialogue";
import { useMainCategory } from "hooks/useMainCategory";
import { useIsMobile } from "hooks";
import LazyImage from "components/LazyImage";
import { FlexBetween } from "components/flex-box";
import { useRouter } from "next/router";
import PowerBuilds from "components/icons/PowerBuilds";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { H1 } from "components/Typography";

type Props = {
  menuOpen?: boolean;
  handleAllClose?: () => void;
  handleOpenThisDrawer?: (d: string) => void;
  toggleIsSearchFocus?: (v: boolean) => void;
  toggleIsMobileSearch?: (v: boolean) => void;
};

const MobileMenuDrawer: FC<Props> = ({
  menuOpen,
  handleAllClose,
  handleOpenThisDrawer,
  toggleIsSearchFocus,
  toggleIsMobileSearch,
}) => {
  const { companyInfoData } = useCompanyInfo();
  const { push } = useRouter();
  const { isUserLogin } = useLoginStatus();
  const isMobile = useIsMobile();
  const { showLoginFormOpen, showSignUpFormOpen } = useDialogueShow();
  const { mainCategories } = useMainCategory();

  const toggleLogin = () => {
    //if user is currently logged in, the header redirect the user to profile else show login form
    if (isUserLogin) {
      push(navigationRoutes.pcwProfile);
    } else {
      showLoginFormOpen();
    }
  };

  // Cannot successfully focus search bar when drawer is open.
  const handleDelayedSearchClick = () => {
    handleAllClose();
    toggleIsMobileSearch(false); // re-load searchbar to focus successfully
    // Added minimal delay to toggle focus when drawer is fully closed.
    setTimeout(() => {
      toggleIsSearchFocus(true);
      toggleIsMobileSearch(true);
    }, 100);
  };

  const apps: NavBarItemList[] = [
    {
      title: "Search",
      icon: IconMenuSearch,
      onClick: handleDelayedSearchClick,
    },
    {
      title: "PC Worth Extra Service",
      icon: SupportAgentIcon,
      href: navigationRoutes.extra_service,
    },
    {
      title: "Powered Builds",
      icon: PowerBuilds,
      href: navigationRoutes.powered_builds,
    },
    {
      title: "PC Builder",
      icon: IconMenuMonitor,
      href: navigationRoutes.diyBuilder,
    },
    {
      title: "PC Gallery",
      icon: IconMenuPictureGallery,
      href: navigationRoutes.buildGallery,
    },
    {
      title: "AI Builder",
      icon: IconMenuRobot,
      href: navigationRoutes.aiBuilder,
    },
    {
      title: "PC Packages",
      icon: IconMenuBox,
      href: navigationRoutes.pcPackage,
    },
    {
      title: "Pricelist",
      icon: IconMenuPricelist,
      href: navigationRoutes.pricelist,
    },
  ];

  // remove promotionals option if flag is false
  if (promotionalsFlag) {
    apps.splice(2, 0, {
      title: "Promos",
      icon: "/assets/images/promo_float.png",
      href: navigationRoutes.promotionals,
    });
  }

  const account: NavBarItemList[] = isUserLogin
    ? [
        {
          title: "Order History",
          icon: IconMenuShoppingBag,
          href: navigationRoutes.pcwOrder,
        },
        {
          title: "My Purchased",
          icon: IconMenuMyPurchased,
          href: navigationRoutes.productWarranty,
        },
        {
          title: "Addresses",
          icon: IconMenuLocation,
          href: navigationRoutes.address,
        },
        {
          title: "Account Security",
          icon: IconMenuSecurity,
          href: navigationRoutes.accountSecurity,
        },
      ]
    : [
        {
          title: "Create Account",
          icon: IconMenuAccount,
          onClick: showSignUpFormOpen,
        },
      ];

  const connect: NavBarItemList[] = [
    {
      title: "Contact Us",
      icon: IconMenuPolicy,
      href: navigationRoutes.contactUs,
    },
    {
      title: "Facebook",
      icon: IconMenuFB,
      onClick: () => {
        openLinkWithApps(
          companyInfoData.facebook,
          companyInfoData.facebookUrlIOS,
          companyInfoData.facebookUrlAndroid
        );
      },
    },
    {
      title: "Tiktok",
      icon: IconMenuTiktok,
      // no android or ios version found on companyInfoData
      onClick: () => {
        openLinkWithApps(companyInfoData.tiktok, "", "");
      },
    },
    {
      title: "Instagram",
      icon: IconMenuInstagram,
      onClick: () => {
        openLinkWithApps(
          companyInfoData.instagram,
          companyInfoData.instagramIos,
          companyInfoData.instagramAndroid
        );
      },
    },
  ];

  const resources: NavBarItemList[] = [
    {
      title: "Terms & Conditions",
      icon: IconMenuTerms,
      href: navigationRoutes.termsAndConditions,
    },
    {
      title: "How To Order",
      icon: IconMenuOrder,
      href: navigationRoutes.howToOrder,
    },
    {
      title: "Frequently Asked Questions",
      icon: IconMenuQuestion,
      href: navigationRoutes.frequentlyAskedQuestions,
    },
    {
      title: "Privacy Policy",
      icon: IconMenuPolicy,
      href: navigationRoutes.privacyPolicy,
    },
  ];

  return isMobile ? (
    <Drawer
      open={menuOpen}
      anchor="right"
      onClose={handleAllClose}
      sx={{
        color: colors.backgroundGrey,
      }}
    >
      <Container sx={{ width: "70vw", padding: 0 }}>
        <FlexBetween mt={1}>
          <ListItemButton onClick={() => handleAllClose()}>
            <ListItemIcon>
              <TurnLeftIcon opacity={0.7} />
            </ListItemIcon>
            <ListItemText sx={{ opacity: 0.8 }}>Back</ListItemText>
          </ListItemButton>
        </FlexBetween>
        <List>
          <Divider sx={{ height: 0.5, bgcolor: colors.grey[400] }} />
          <ListItemButton href={"/"}>
            <ListItemIcon>
              <IconMenuHome />
            </ListItemIcon>
            <ListItemText sx={{ opacity: 0.8 }}>Home</ListItemText>
          </ListItemButton>
          {isUserLogin ? (
            <ListItemButton onClick={() => toggleLogin()}>
              <ListItemIcon>
                <IconMenuProfileInfo />
              </ListItemIcon>
              <ListItemText sx={{ opacity: 0.8 }}>Profile Info</ListItemText>
            </ListItemButton>
          ) : (
            <ListItemButton onClick={() => showLoginFormOpen()}>
              <ListItemIcon>
                <IconMenuSignIn />
              </ListItemIcon>
              <ListItemText sx={{ opacity: 0.8 }}>Sign In</ListItemText>
            </ListItemButton>
          )}
          {mainCategories.map((item) =>
            item ? (
              <MainMenuDrawerItem
                item={item}
                key={item.slug}
                onCategoryClick={() => handleOpenThisDrawer(item.slug)}
                isMainCateg={true}
              />
            ) : null
          )}
        </List>
        <List
          sx={{
            textAlign: "center",
            paddingBottom: "0px",
          }}
        >
          <H1
            sx={{
              textAlign: "center",
              mt: 1,
              padding: "10px",
              background: "#0c304a",
              color: "#FFFFFF",
              pl: 3,
            }}
          >
            Powered By
          </H1>
          <Button
            variant="text"
            href={navigationRoutes.poweredByGigabyte}
            endIcon={
              <LazyImage
                height="10px"
                width="80px"
                src={"/assets/images/brands/gigabyte_logo.png"}
              />
            }
            sx={{
              width: "90%",
              mt: 1,
              mb: 1,
              borderRadius: "4px",
            }}
          />
          <Divider sx={{ height: 0.5, bgcolor: colors.grey[400] }} />
          <Button
            variant="text"
            href={navigationRoutes.poweredByAsrock}
            endIcon={
              <LazyImage
                height="10px"
                width="75x"
                src={"/assets/images/brands/asrock_logo.png"}
              />
            }
            sx={{
              width: "90%",
              mt: 1,
              borderRadius: "4px",
            }}
          />
          <Divider sx={{ height: 0.5, bgcolor: colors.grey[400] }} />
          <Button
            variant="text"
            href={navigationRoutes.poweredByAsus}
            endIcon={
              <LazyImage
                height="10px"
                width="60px"
                src={"/assets/images/brands/asus_logo.png"}
              />
            }
            sx={{
              width: "90%",
              mt: 1,
              borderRadius: "4px",
            }}
          />
          <Divider sx={{ height: 0.5, bgcolor: colors.grey[400] }} />
          <Button
            variant="text"
            href={navigationRoutes.poweredByMSI}
            endIcon={
              <LazyImage
                height="10px"
                width="50px"
                src={"/assets/images/brands/msi_logo.png"}
              />
            }
            sx={{
              width: "90%",
              mt: 1,
              borderRadius: "4px",
            }}
          />
        </List>
        <List>
          <Divider sx={{ height: 0.5, bgcolor: colors.grey[400] }} />
          <MenuDrawerTitle title={"APPS"} />
          {apps.map((item) =>
            item ? <MainMenuDrawerItem item={item} key={item.title} /> : null
          )}

          {blogsFlag && (
            <ListItemButton href={navigationRoutes.blogs}>
              <ListItemIcon>
                <NewspaperOutlinedIcon sx={{ color: "#AEB4BE" }} />
              </ListItemIcon>
              <ListItemText sx={{ opacity: 0.8 }}>
                Blogs n Articles
              </ListItemText>
            </ListItemButton>
          )}
        </List>
        {accountMenuOptionsFlag ? (
          <List>
            <Divider sx={{ height: 0.5, bgcolor: colors.grey[400] }} />
            <MenuDrawerTitle title={"ACCOUNT"} />
            {account.map((item) =>
              item ? <MainMenuDrawerItem item={item} key={item.title} /> : null
            )}
          </List>
        ) : null}
        <List>
          <Divider sx={{ height: 0.5, bgcolor: colors.grey[400] }} />
          <MenuDrawerTitle title={"CONNECT"} />
          {connect.map((item) =>
            item ? <MainMenuDrawerItem item={item} key={item.title} /> : null
          )}
        </List>
        <List sx={{ paddingBottom: "25px" }}>
          <Divider sx={{ bgcolor: colors.backgroundGrey }} />
          <MenuDrawerTitle title={"RESOURCES"} />
          {resources.map((item) =>
            item ? <MainMenuDrawerItem item={item} key={item.title} /> : null
          )}
        </List>
      </Container>
    </Drawer>
  ) : null;
};
export default MobileMenuDrawer;
