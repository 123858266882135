import { Box, Collapse } from "@mui/material";
import React, { FC, memo } from "react";
import Link from "next/link";

interface Props {
  open: boolean;
}

export const MobileInformation: FC<Props> = ({ open }) => {
  return (
    <Collapse in={open} sx={{ paddingLeft: "2.3em" }}>
      <Box py={0.6}>
        <Link href={"/corporate"}>PC Worth Corporate</Link>
      </Box>
      <Box py={0.6}>
        <Link href={"/contact-us"}>Contact Us</Link>
      </Box>
      <Box py={0.6}>
        <Link href={"/terms-and-conditions"}>Terms & Conditions</Link>
      </Box>
      <Box py={0.6}>
        <Link href={"/how-to-order"}>How To Order</Link>
      </Box>
      <Box py={0.6}>
        <Link href={"/frequently-ask-question"}>Frequently Ask Question</Link>
      </Box>
      <Box py={0.6}>
        <Link href={"/privacy-policy"}>Privacy Policy</Link>
      </Box>
      <Box py={0.6}>
        <Link href={"/careers"}>Careers</Link>
      </Box>
    </Collapse>
  );
};
