import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { drjInfo, drjInfoAlabang, pcwExperience } from "common";
import { CompanyInfoBranches } from "./types";

const branchesList = [drjInfo, drjInfoAlabang, pcwExperience];

type State = {
  branches: CompanyInfoBranches[];
};

const initialState: State = {
  branches: branchesList,
};

const slice = createSlice({
  name: "companyInfoSlice",
  initialState,
  reducers: {},
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions };
